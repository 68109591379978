import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setField} from "../app/slices";


const Jmp = (props) => {
  const dispatch = useDispatch();
  const{ newuser,name} = useSelector((state) => state.chatslice);
  const [olduser,setolduser] = useState(false);

  console.log(newuser,"newser,name",name);

  useEffect(()=>{
    if(olduser){
      props.parse('existing_user9763453648934');
    }
  },[olduser])
  
  useEffect(()=>{
    if(newuser){
      props.parse("name");
     console.log(newuser,"newser,name",name);
    }
   
    console.log(newuser,"newser,name",name)
  },[newuser])


  const handleuser =  ({ target: { value } }) => { 
    //debugger
    if (value == "newuser3234457435") {
    
     dispatch(setField({ field: 'newuser', value: true }));
     dispatch(setField({ field: 'name', value: true }));
    } else {
     
      dispatch(setField({ field: 'newuser', value: false }));
      dispatch(setField({ field: 'name', value: false }));
      dispatch(setField({ field: 'email', value: false }));
      dispatch(setField({ field: 'mobile', value: false }));
      setolduser(true)
     
    }
  };

  console.log(newuser,"newser,name",name);

  return (
    <div
      style={{
        backgroundColor: "#5869FF",
        padding: "5px",
        color: "#FFFFFF",
        borderRadius: "5px",
      }}
    >
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <input
          type="radio"
          name="userquery"
          id="user"
          value="existing_user9763453648934"
          style={{ marginRight: "12px", marginTop: "7px" }}
          onChange={handleuser}
        />
        <label htmlFor="user">Are you an existing user?</label>
      </div>
      <div style={{ display: "flex" }}>
        <input
          type="radio"
          name="userquery"
          id="newuser"
          value="newuser3234457435"
          style={{ marginRight: "12px" }}
          onChange={handleuser}
        />
        <label htmlFor="newuser">Are you new user?</label>
      </div>
    </div>
  );
};

export default Jmp;
