import React, { useState, useEffect, useCallback } from "react";
import { useFormik } from "formik";
// React Icon
import { FaUserTie } from "react-icons/fa";
import { HiOutlinePhoneOutgoing } from "react-icons/hi";
import { MdOutlineBook } from "react-icons/md";
import { MdKeyboardArrowDown } from "react-icons/md";
import { ScheduleCallSchema } from "../../schema/FormValidation";
import { TfiAgenda } from "react-icons/tfi";
import { FaTreeCity } from "react-icons/fa6";
// Import Toast
import Toast from "../../components/Toast";

// Controller Api Methods
import {
  createScheduleCall,
  callRelatedDropdowns,
  callPurposeDropdowns,
  fetchCalllog,
  fetchRelatedlog,
} from "../../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate, Link } from "react-router-dom";
import Datalist from "../../components/datalist";

// Get TokenId and Uid
const userIdTokenData = JSON.parse(localStorage.getItem("user"));
const uid = userIdTokenData?.data?.userId;
const tokenId = userIdTokenData?.data?.token;

const ScheduleCall = () => {
  const navigate = useNavigate();

  // Toast
  const [showToast, setShowToast] = useState(false);
  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  if (showToast) {
    hideToast();
  }
  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      callTo: "",
      relatedTo: "",
      callType: "",
      callStatus: "",
      callStartTime: "",
      callOwner: userIdTokenData?.data?.fullName || "",
      subject: "",
      reminder: "",
      leadId: "",
      callPurpose: "",
      callAgenda: "",
      callToName:"",
      relatedToName:""
    },
    validationSchema: ScheduleCallSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        await createScheduleCall(uid, values, setShowToast, tokenId);
        resetForm();
      } catch (error) {
        console.log("Did Not Create Account", error);
      } finally {
        setSubmitting(false);
      }
    },
  });


  // Related To
  const [relatedTo, setRelatedTo] = useState();
  const getCallRelatedDropdowns = useCallback(async () => {
    try {
      const callRelatedDropdown = await callRelatedDropdowns(tokenId);
      setRelatedTo(callRelatedDropdown);
    } catch (error) {
      console.log(error);
    }
  }, []); //do not add dispatch because infinite loop start

  // Purpose
  const [callPurpose, setCallPurpose] = useState();
  const getCallPurposeDropdowns = useCallback(async () => {
    try {
      const callPurposeDropdownsResult = await callPurposeDropdowns(tokenId);
      setCallPurpose(callPurposeDropdownsResult);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Set Current Date and Time
  const [currentDateTime, setCurrentDateTime] = useState("");
  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const dd = String(today.getDate()).padStart(2, "0");
    const hh = String(today.getHours()).padStart(2, "0");
    setCurrentDateTime(`${yyyy}-${mm}-${dd}T${hh}:00`);
    getCallRelatedDropdowns();
    getCallPurposeDropdowns();
  }, [getCallRelatedDropdowns, getCallPurposeDropdowns]);

//  search call api for callTo
const [datalist,setdatalist] = useState([]);
   const searchCallToApi = async (e)=>{
     const value = e.target.value;
     const response = await fetchCalllog(value,values.callTo,tokenId,setShowToast);
     setdatalist(response);
     
   }
 
    const searchRelatedToToApi = async (e)=>{
      const value = e.target.value;
      const response = await fetchRelatedlog(value,values.relatedTo,tokenId,setShowToast);
      setdatalist(response);
    }
  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link>
      <form onSubmit={handleSubmit}>
        {/* Create Schedule Call Heading */}
        <div className="create-lead-heading">
          <div>
            <p className="create_lead_section2_company_info">
              Call Information
            </p>
          </div>
          {/* <div className="create-lead-heading-right-part">
            <p className="create-lead-heading-right-para">
              Lead Name :{" "}
              <span className="create-lead-heading-span">
                Pankaj Swami Vaishnav
              </span>
            </p>
            <p className="create-lead-heading-right-para">
              Lead Id : <span className="create-lead-heading-span">2024</span>
            </p>
          </div> */}
        </div>
        {/* User Account Information */}
        <div className="row">
          {/* <p className="create_lead_section2_company_info">Call Information</p> */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callTo">
              Call To <span className="required_sign">*</span>
            </label>
            <div className="create_call_form">
              <div>
                <select
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: "15px",
                  }}
                  id="callTo"
                  // className="form-control"
                  value={values.callTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="callTo"
                >
                  <option value="" hidden>
                    Call to
                  </option>
                  <option value="lead">Lead</option>
                  <option value="contact">Contact</option>
                </select>
              </div>
              <div
                style={{
                  width: "100%",
                  borderLeft: "1px solid rgba(128, 128, 128, 0.24)",
                  position:"relative"
                }}
              >
                <input autoComplet={0}
                list="callList"
                  disabled={!values.callTo}
                  type="text"
                  maxLength={30}
                  placeholder={values.callTo?"Search.....":"Please select type"}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: "15px",
                  }}
                  value={values.callToName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={searchCallToApi}
                  name="callToName"
                />
                <datalist id="callList" style={{maxHeight:"350px"}}>
                  {datalist?.map((data)=><option value={data?.name} style={{padding:"4px",borderBottom:"1px solid rgba(216, 213, 213, 0.24)"}}>{data?.name}</option>)}
                 {/* <Datalist data={datalist}/> */}
                </datalist>
                {/* {datalist?.length>0 &&(
                  <div  className="datalist_dropdown">
                    <Datalist data={datalist}/>
                </div>
                )} */}
                
              </div>
            </div>
            {/* <label htmlFor="callTo">
              Call To <span className="required_sign">*</span>
            </label>
            <select
              id="callTo"
              className="form-control"
              value={values.callTo}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callTo"
            >
              <option value="" hidden>
                Call to
              </option>
              <option value="lead">Lead</option>
              <option value="contact">Contact</option>
            </select> */}

            {touched.callTo && errors.callTo && (
              <small className="errorMessage">{errors.callTo}</small>
            )}
            {touched.callToName && errors.callToName &&values?.callTo&& (
              <small className="errorMessage">{errors.callToName}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
          </div>
          {/* new related to field */}
         
          {/* Related to */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="relatedTo">
              Related To <span className="required_sign">*</span>
            </label>
            <div className="create_call_form">
              <div>
                <select
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: "15px",
                  }}
                  id="relatedTo"
                  // className="form-control"
                  value={values.relatedTo}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="relatedTo"
                >
                  <option value="" hidden>
                    Related to
                  </option>
                  {relatedTo && relatedTo?.length > 0
                    ? relatedTo.map((item) => (
                        <option key={item?.id} value={item?.value}>
                          {item?.relatedTo}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
              <div
                style={{
                  width: "100%",
                  borderLeft: "1px solid rgba(128, 128, 128, 0.24)",
                }}
              >
                <input autoComplet={0}
                list="relatedList"
                  disabled={!values.relatedTo}
                  type="text"
                  maxLength={30}
                  placeholder={values.relatedTo?"Search.....":"Please select type"}
                  style={{
                    border: "none",
                    outline: "none",
                    paddingLeft: "15px",
                  }}
                  value={values.relatedToName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onInput={searchRelatedToToApi}
                  name="relatedToName"
                />
                <datalist id="relatedList" >
                 <Datalist data={datalist}/>
                </datalist>
              </div>
            </div>
            {/* ))))))))))))))))))))))) */}
            {/* <select
              id="relatedTo"
              className="form-control"
              value={values.relatedTo}
              onChange={handleChange}
              onBlur={handleBlur}
              name="relatedTo"
            >
              <option value="" hidden>
                Related to
              </option>
              {relatedTo && relatedTo?.length > 0
                ? relatedTo.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.relatedTo}
                    </option>
                  ))
                : ""}
            </select> */}
            {touched.relatedTo && errors.relatedTo && (
              <small className="errorMessage">{errors.relatedTo}</small>
            )}
             {touched.relatedToName && errors.relatedToName &&values?.relatedTo&& (
              <small className="errorMessage">{errors.relatedToName}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
            {/* )))))))))))))))))))))))))))))))))))))) */}
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callType">
              Call Type <span className="required_sign">*</span>
            </label>
            <input type="text" name="callType" id="callType" value="Outbound" />
            {/* <select
              id="callType"
              className="form-control"
              
              value="Outbound"
              onChange={handleChange}
              onBlur={handleBlur}
              name="callType"
            >
            
              <option value="outbound" selected>outbound</option>
             
            </select> */}
            {touched.callType && errors.callType && (
              <small className="errorMessage">{errors.callType}</small>
            )}
            {/* <MdKeyboardArrowDown className="create_lead_input_icon" /> */}
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callStatus">
              Outgoing Call Status <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callStatus"
              className="form-control create_lead_form_input"
              // value={values.callStatus}
              value="Scheduled"
              onChange={handleChange}
              onBlur={handleBlur}
              name="callStatus"
              placeholder="Enter call status"
            />
            {touched.callStatus && errors.callStatus && (
              <small className="errorMessage">{errors.callStatus}</small>
            )}
            <HiOutlinePhoneOutgoing className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callStartTime">
              Call Start Time <span className="required_sign">*</span>
            </label>
            <input
              type="datetime-local"
              id="callStartTime"
              min={currentDateTime}
              className="form-control create_lead_form_input"
              value={values.callStartTime}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callStartTime"
            />
            {touched.callStartTime && errors.callStartTime && (
              <small className="errorMessage">{errors.callStartTime}</small>
            )}
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callOwner">
              Call Owner <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callOwner"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.callOwner}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callOwner"
              placeholder="Enter call owner name"
            />
            {touched.callOwner && errors.callOwner && (
              <small className="errorMessage">{errors.callOwner}</small>
            )}
            <FaUserTie className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="accountNumber">
              Subject <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="subject"
              className="form-control create_lead_form_input"
              value={values.subject}
              onChange={handleChange}
              onBlur={handleBlur}
              name="subject"
              placeholder="Enter subject"
            />
            {touched.subject && errors.subject && (
              <small className="errorMessage">{errors.subject}</small>
            )}
            <MdOutlineBook className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="reminder">
              Reminder <span className="required_sign">*</span>
            </label>
            <select
              id="reminder"
              className="form-control"
              value={values.reminder}
              onChange={handleChange}
              onBlur={handleBlur}
              name="reminder"
            >
              <option value="">
                {/* {touched.reminder && errors.reminder ? (
                  <p className="text-danger">{errors.reminder}</p>
                ) : (
                  "None"
                )} */}
                None
              </option>
              <option value="5">5 Minute Before</option>
              <option value="10">10 Minute Before</option>
              <option value="15">15 Minute Before</option>
              <option value="30">30 Minute Before</option>
            </select>
            {touched.reminder && errors.reminder && (
              <small className="errorMessage">{errors.reminder}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="leadId">
              Lead Id <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="leadId"
              className="form-control create_lead_form_input"
              value={values.leadId}
              onChange={handleChange}
              onBlur={handleBlur}
              name="leadId"
              placeholder="Enter address"
            />
            {touched.leadId && errors.leadId && (
              <small className="errorMessage">{errors.leadId}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
        </div>
        {/* Calling Purpose */}
        <div className="row">
          <p className="create_lead_section2_company_info">
            Purpose Of Outgoing Call
          </p>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callPurpose">
              Call Purpose <span className="required_sign">*</span>
            </label>
            <select
              id="callPurpose"
              className="form-control"
              value={values.callPurpose}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callPurpose"
            >
              <option value="">
                {/* {touched.callPurpose && errors.callPurpose ? (
                  <p className="text-danger">{errors.callPurpose}</p>
                ) : (
                  "None"
                )} */}
                None
              </option>
              {callPurpose && callPurpose?.length > 0
                ? callPurpose.map((item) => (
                    <option key={item?.id} value={item?.value}>
                      {item?.callPurpose}
                    </option>
                  ))
                : ""}
            </select>
            {touched.callPurpose && errors.callPurpose && (
              <small className="errorMessage">{errors.callPurpose}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callAgenda">
              Call Agenda <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callAgenda"
              className="form-control create_lead_form_input"
              value={values.callAgenda}
              onChange={handleChange}
              onBlur={handleBlur}
              name="callAgenda"
              placeholder="Enter call agenda "
            />
            {touched.callAgenda && errors.callAgenda && (
              <small className="errorMessage">{errors.callAgenda}</small>
            )}
            <TfiAgenda className="create_lead_input_icon" />
          </div>
        </div>
        {/* Submit Button */}
        <div className="text-center mb-2">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={isSubmitting}
          >
            Submit
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default ScheduleCall;
