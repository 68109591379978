

 
import React, { useEffect, useState } from 'react'
import { getUserImage } from '../../controller/fetchApi';
import { Link } from 'react-router-dom';

function Logo({ updatelogo,shrinkSidebar}) {
    const [logo, setlogo] = useState(null);

    const userIdTokenData = JSON.parse(localStorage.getItem("user"));
    const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
    const tokenId = userIdTokenData?.data?.token;

    const userImage = async () => {
        const res = await getUserImage(tokenId);
        if (res) {
        //   setImage(res?.data?.data?.userImage);
          setlogo(res?.data?.data?.companyLogo);
        }
      };
      useEffect(() => {
        userImage();
      }, [updatelogo]);
  return (
    <div style={{height:"7vh",display:"flex",alignItems:"center",justifyContent:"start"}}> <Link
    className="navbar-brand dashbaord_navbar_crm_text"
    to="/dashboard"
  >
    {logo && <img width="45px" className="LOgo_Image" src={logo} alt="C" />}
    {shrinkSidebar&&(<> CR<span>M</span></>)}
  </Link></div>
  )
}

export default Logo