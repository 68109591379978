import Toast from "./Toast";
import { useFormik } from "formik";
import { BsBuildingsFill } from "react-icons/bs";
import { FaPhone } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { createCompany, updateCompanyDetail } from "../controller/fetchApi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { CreateCompanySchema } from "../schema/FormValidation";

const UpdateCompanyDetails = () => {
    
  const navigate = useNavigate();
  const location = useLocation();
  const companydetail = location.state?.userData;
  const companyId=companydetail?.id
 
  // Start Toast Code -------
  const [showToast, setShowToast] = useState({ success: false, message: "" });
  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" });
    }, 3000);
  };
  if (showToast.message) {
    hideToast();
  }

  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues: {
      firstName:companydetail?companydetail.firstName:"",
      lastName: companydetail?companydetail.lastName:"",
      userName: companydetail?companydetail.userName:"",
      password: "",
      confirmPassword: "",
      email: companydetail?companydetail.email:"",
      mobile: companydetail?companydetail.mobile:"",
      companyName: companydetail?companydetail.companyName:"",
      companyEmail: companydetail?companydetail.companyEmail:"",
      companyContact: companydetail?companydetail.companyContact:"",
      companyAddress: companydetail?companydetail.companyAddress:"",
      // numberOfOffice: "",
      // numberOfDepartment: "",
      // numberOfDesignation: "",
    },

    validationSchema: CreateCompanySchema,
  });

 const updatedetail = async (event)=>{event.preventDefault();
    try{
       const res = await updateCompanyDetail(values, setShowToast, tokenId,companyId);

       if(res?.data?.status===200){
        navigate("/companylist",{replace:true})
       }
    }
    catch(error){

    } 
 }

  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };
  // Show & Hide Password
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
      <Link onClick={() => navigate(-1)}>
        <IoArrowBackCircleOutline
          className="_back_button"
          style={{ width: "60px" }}
        />
      </Link>
      <form onSubmit={updatedetail}>
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Company Details
          </p>
        </div>
        {/* Company Information */}
        <div className="row">
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyName">
              Company Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="companyName"
              maxLength={40}
              className="form-control create_lead_form_input"
              value={
                 values.companyName
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyName"
              placeholder="Enter company name"
            />
            {touched.companyName && errors.companyName && (
              <small className="errorMessage">{errors.companyName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyEmail">
              Company Email <span className="required_sign">*</span>
            </label>
            <input
              type="email"
              id="companyEmail"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={
                values.companyEmail
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyEmail"
              placeholder="Enter Company Email"
            />
            {touched.companyEmail && errors.companyEmail && (
              <small className="errorMessage">{errors.companyEmail}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyContact">
              Company Contact <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="companyContact"
             maxLength={10}
              className="form-control create_lead_form_input"
              value={
                values.companyContact
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyContact"
              placeholder="Enter company contact"
            />
            {touched.companyContact && errors.companyContact && (
              <small className="errorMessage">{errors.companyContact}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyAddress">
              Company Address <span className="required_sign">*</span>
            </label>
            <textarea
              id="companyAddress"
              maxLength={250}
              className="form-control create_lead_form_input"
              value={
                 values.companyAddress
              }
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyAddress"
              placeholder="Enter Company address"
            />

            {touched.companyAddress && errors.companyAddress && (
              <small className="errorMessage">{errors.companyAddress}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
         
        </div>
        {/* admin detail */}
        <div>
          <p className="create_lead_section2_company_info mt-3">
            Admin Details
          </p>
        </div>
        <div className="row">
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="firstName">
              Firstname <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.firstName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="firstName"
              placeholder="Enter Firstname"
            />
            {touched.firstName && errors.firstName && (
              <small className="errorMessage">{errors.firstName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="lastName">
              Lastname <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              maxLength={20}
              id="lastName"
              className="form-control create_lead_form_input"
              value={ values.lastName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="lastName"
              placeholder="Enter Lastname "
            />
            {touched.lastName && errors.lastName && (
              <small className="errorMessage">{errors.lastName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="userName">
              username <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="userName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={ values.userName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="userName"
              placeholder="Enter username "
            />
            {touched.userName && errors.userName && (
              <small className="errorMessage">{errors.userName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="email">
              Email <span className="required_sign">*</span>
            </label>
            <input
              type="email"
              maxLength={50}
              id="email"
              className="form-control create_lead_form_input"
              value={ values.email}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="email"
              placeholder="Enter Email"
            />
            {touched.email && errors.email && (
              <small className="errorMessage">{errors.email}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="mobile">
              Mobile Number<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="mobile"
            maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobile}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobile"
              placeholder="Enter  Mobile Number"
            />
            {touched.mobile && errors.mobile && (
              <small className="errorMessage">{errors.mobile}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>

         
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            className="create_lead_form_submitBtn"
            type="submit"
            // disabled={isSubmitting}
            
          >
           update
          </button>
        </div>
        {/* <input type="submit" value="subnit" /> */}
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};
export default UpdateCompanyDetails;
