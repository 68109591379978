 

  import React from 'react'
  
  function Datalist({data}) {
  
    return (
      <>
      {data?.map(data=><option value={data?.name} style={{padding:"4px",borderBottom:"1px solid rgba(216, 213, 213, 0.24)"}}>{data?.name}</option>)}
      </>
    )
  }
  
  export default Datalist;