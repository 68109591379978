import React, { useEffect } from "react";
// Component
import CreateUpdateForm from "../../components/CreateUpdateForm";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";


const UpdateLead = ({ leadCostumerId, defaultValue, getLeadsData,leadData,setupdatedLead,setupdatelead }) => {
 
  const navigate = useNavigate()

  return (
    <div className="container-fluid dashboard_create_lead_main_container">
         <span onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </span>
      {/* Lead Update Form Components */}
      <div className="create_leads_form_div">
        <CreateUpdateForm
      setupdatelead={setupdatelead}
        setupdatedLead={setupdatedLead}
        leadData = {leadData}
          leadCostumerId={leadCostumerId}
          defaultValue={defaultValue}
          getLeadsData = {getLeadsData}
        />
      </div>
    </div>
  );
};

export default UpdateLead;
