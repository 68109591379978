import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices";
import { dropDownReducer,chatreducer } from "./slices";
export const store = configureStore({
  reducer: {
    userData: userSlice,
    dropDown: dropDownReducer,
    chatslice:chatreducer,
  },
}); 
console.log(store,'store store store store store')
