import { useFormik } from "formik";
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { logoutUser } from "../controller/fetchApi";
import { pinValidation } from "../schema/FormValidation";

const Setpin = ({ setpin }) => {
  const navigate = useNavigate()
  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

  //form handelling using formik
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      dig1: "",
      dig2: "",
      dig3: "",
      dig4: "",
    },
   validationSchema:pinValidation,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const data = values.dig1 + values.dig2 + values.dig3 + values.dig4;
      setpin(data);
    },
  });
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  // const [pin, setPin] = useState({
  //   dig1: null,
  //   dig2: null,
  //   dig3: null,
  //   dig4: null,
  // });
  const [focus, setFocus] = useState("dig1");

  // Create refs for each input field
  const dig1Ref = useRef(null);
  const dig2Ref = useRef(null);
  const dig3Ref = useRef(null);
  const dig4Ref = useRef(null);

  useEffect(() => {
    // Focus the input element based on the focus state
    if (focus === "dig1") dig1Ref.current?.focus();
    if (focus === "dig2") dig2Ref.current?.focus();
    if (focus === "dig3") dig3Ref.current?.focus();
    if (focus === "dig4") dig4Ref.current?.focus();
  }, [focus]);

  const handleMpin = ({ target: { name, value, id } }) => {
    if (value) {
      //  setPin((prev) => ({ ...prev, [name]: value }));

      let n = Number(name[3]);
      if (n < 4) {
        n += 1;
      } else {
        n = (n + 1) % 4;
      }

      setFocus(`dig${n}`);
    }
  };

  // const handleform = async (e) => {
  //   let data = pin.dig1 + pin.dig2 + pin.dig3 + pin.dig4;
  //   e.preventDefault();
  //    setpin(data);
  // };
  
  // Logout User Api Call
  const logoutUserSubmit = () => {
    logoutUser();
    localStorage.clear();
    navigate("/login")
    // window.location.href = "/login";
  };

  return (
    <div
      style={{
        width: "50%",
        padding: "100px 50px 50px 50px",
        backgroundColor: "white",
        borderRadius: "20px",
      }}
    >
      {/* <a className="dropdown-item" href="/signin" onClick={logoutUserSubmit}>
        <Link to="/dashboard">
          <IoArrowBackCircleOutline className="login_back_button" />
        </Link>
        Logout
      </a> */}
 
      <Link onClick={logoutUserSubmit}>
          <IoArrowBackCircleOutline className="login_back_button" />
        </Link>
      <div>
        <form
          onSubmit={handleSubmit}
          //onSubmit={handleform}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "80%",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <input 
            className="MPIN_digit"
              ref={dig1Ref}
              type="text"
              name="dig1"
              maxLength={1}
              style={{
                width: "50px",
                height: "40px",
                padding: "8px",
                textAlign: "center",
                marginRight: "15px",
                outline: "none",
              }}
              placeholder={touched.dig1&&errors.dig1?errors.dig1:null}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onInput={handleMpin}
            />
            <input
              className="MPIN_digit"
              ref={dig2Ref}
              type="text"
              name="dig2"
              maxLength={1}
              style={{
                width: "50px",
                height: "40px",
                padding: "8px",
                textAlign: "center",
                marginRight: "15px",
                outline: "none",
              }}
              placeholder={touched.dig2&&errors.dig2?errors.dig2:null}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onInput={handleMpin}
            />
            <input
              className="MPIN_digit"
              ref={dig3Ref}
              type="text"
              name="dig3"
              maxLength={1}
              style={{
                width: "50px",
                height: "40px",
                padding: "8px",
                textAlign: "center",
                marginRight: "15px",
                outline: "none",
              }}
              placeholder={touched.dig3&&errors.dig3?errors.dig3:null}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onInput={handleMpin}
            />
            <input
              className="MPIN_digit"
              ref={dig4Ref}
              type="text"
              name="dig4"
              maxLength={1}
              style={{
                width: "50px",
                height: "40px",
                padding: "8px",
                textAlign: "center",
                marginRight: "15px",
                outline: "none",
              }}
              placeholder={touched.dig4&&errors.dig4?errors.dig4:null}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onInput={handleMpin}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "60px",
            }}
          >
            <span
              style={{ fontSize: "18px", fontWeight: "700", color: "grey" }}
            >
              Set your MPIN
            </span>
            <button
              type="submit"
              style={{
                border: "2px solid grey",
                borderRadius: "5px",
                color: "#696b6a",
                padding: "4px 7px",
              }}
            >
              SET
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Setpin;
