import React, { useEffect, useState } from "react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { getuserLog } from "../controller/fetchApi";
import Drop_controler from "../images/down_arrow.png";
import DetailLog from "../components/detailLog";
import Loader from "./Loader";

function UserActivityLog() {
  const navigate = useNavigate();
  const location = useLocation();
  const userId = location?.state?.userid;

  const [logData, setlogData] = useState(null);
  const [showlog, setShowLog] = useState("");
  const [open, setopen] = useState(false);
  const [loading, setloading] = useState(true);
  // open detail
  const [openDetail,setopenDetail] = useState("")

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  //   month
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const userlogInfo = async (userId, tokenId) => {
    const response = await getuserLog(userId, tokenId);
    if (response) {
      setlogData(response?.data);
      setloading(false)
    }
  };
  useEffect(() => {
    userlogInfo(userId, tokenId);
  }, []);

  const openHistory = (key) => {
    setShowLog((prev) => {
      prev !== key ? setopen(true) : setopen((prev) => !prev);
      return key;
    });
  };

  return (
 <>
 <div
      className="conatiner-fluid dashboard_rightLeads_main_container"
      style={{ padding: "10px 20px 20px", maxHeight: "100%" }}
    >
      <div
        className="col-12"
        style={{ height: "8%", borderBottom: "1px solid #dedad9" }}
      >
        <span onClick={() => navigate(-1)}>
          <IoArrowBackCircleOutline
            className="_back_button"
            style={{ width: "60px" }}
          />
        </span>
      </div>
      {loading?<Loader/>:
   ( 
      <div
        className="dashboard_content_wrapper log_data_displayer"
        style={{ maxHeight: "92%",overflow:"auto"}}
      >
        {logData?.length?logData?.map((history) => (
          <div
            key={history?.date}
            className="col-12 position-relative"
            style={{
              border: "1px solid #c4c3c2",
              borderRadius: "8px",
              marginTop:"10px",
              boxShadow: "1px 1px 1px 1px #f0efed",
              padding: "0px 15px",
              maxHeight:"720px",
            }}
          >
            <div
              style={{
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "context-menu",
              }}
              onClick={() => openHistory(history?.date)}
            >
              <span>{`${
                months[Number(history?.date?.slice(5, 7)) - 1]
              } ${history?.date?.slice(8, 10)}, ${history?.date?.slice(
                0,
                4
              )}`}</span>
              <span>
                <img src={Drop_controler} width="30px" />
              </span>
            </div>
            {open && showlog === history?.date && (
              <div
              //  className="table_log" 
              style={{ width: "95%",maxHeight:'670px',overflow:"hidden"}}
              >
                <div className="table" style={{maxHeight:"100%",width:"100%"}}>
                  
                    <ul style={{display:"flex",alignItems:"center",justifyContent:"space-between"}} >
                    <li className="text-left col-xl-2 log_head" style={{listStyle:'none', borderBottom:"1px solid #f0eded",paddingLeft:"15px"}}>
                       Sr. No
                      </li>
                     
                      <li className="text-left col-xl-3 log_head" style={{listStyle:'none', borderBottom:"1px solid #f0eded",paddingLeft:"15px"}}>
                        Change Type
                      </li>
                      <li className="text-left col-xl-3 log_head" style={{listStyle:'none', borderBottom:"1px solid #f0eded",paddingLeft:"15px"}}>
                        Changed By
                      </li>
                      <li className="text-left col-xl-2 log_head" style={{listStyle:'none', borderBottom:"1px solid #f0eded",paddingLeft:"15px"}}>
                        Time
                      </li>
                      <li className="text-left col-xl-2 log_head" style={{listStyle:'none', borderBottom:"1px solid #f0eded",paddingLeft:"15px"}}>
                       Details
                      </li>
                    </ul>
              
                  {/* <tbody
                    className="log_data_displayer table"
                    style={{maxHeight:"550px",overflow:"auto",display:"flex",alignItems:"center"}}
                  > */}
                  <div  className="log_data_displayer" style={{width:"100%",maxHeight:"520px",overflow:"auto"}}>
                    {history?.logs?.map((detailLog,index) => (
                      <ul key={detailLog?.id} className="th-design" style={{display:"flex",alignItems:"center"}}>
                     <li className="text-left col-xl-2 log_head" style={{listStyle:'none',paddingLeft:"15px"}}>
                   <span style={{display:"inline-block",padding:"2px 7px",border:"1px solid #dedddc",borderRadius:"2px"}}>{index+1}</span>
                      </li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px"}}
                          className="text-left col-xl-3"
                        >{`${detailLog?.module} ${detailLog?.action}`}</li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px"}}
                          className="text-left col-xl-3"
                        >
                          {detailLog?.performedBy}
                        </li>
                        <li
                          style={{ fontSize: "15px",listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"15px"}}
                          className="text-left col-xl-2"
                        >
                          {detailLog?.time}
                        </li>
                        <li style={{listStyle:'none' ,verticalAlign:'middle',height:"30px",paddingLeft:"35px",position:"relative"}}
                        className="text-left col-xl-2"><span style={{padding:'5px 10px',cursor:"pointer"}} onClick={(event)=>{event.stopPropagation();
                          setopenDetail(detailLog?.id)}}>...</span>
                          {openDetail===detailLog?.id&&
                          <DetailLog setopenDetail={setopenDetail} details={detailLog?.details} description={detailLog?.description}/>
                          }
                         </li>
                      </ul>
                    ))}
                    </div>
                  {/* </tbody> */}
                </div>
              </div>
            )}
          </div>
        )):<p style={{fontSize:"15px",fontWeight:"500",padding:'10px 20px'}}>No log data found.</p>}
      </div>
      )}
    </div>
    </>
  );
}

export default UserActivityLog;
