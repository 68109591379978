// in config.js
import Boatoptions from './boatoptions'
import { createChatBotMessage,createCustomMessage,createClientMessage } from 'react-chatbot-kit';
import Jmp from './jmp';
import Realchat from './realchat';
import Chatheader from './chatboatheader';
const botName = 'CRMBot';
 
const config = {
  initialMessages: [createChatBotMessage(` Hi! I'm CRM  assistant.. I am here to help you!!!.`),createChatBotMessage("Tell me about you!",{widget:"Realchat"})],
  botName: botName,
  customStyles: {
    botMessageBox: {
      backgroundColor:'#5869FF',
    },
    chatButton: {
      backgroundColor: '#5869FF',
    },
  },
  widgets: [
   
    {
      widgetName: 'Realchat',
      widgetFunc: (props) => <Realchat {...props} />,   
    },
  ],
  customComponents: {
    // Replaces the default header
   header: () => <Chatheader />,
   // Replaces the default bot avatar
   botAvatar: (props) => <div style={{width:"45px",marginRight:"10px",color:"white",height:"45px",borderRadius:"50%",
     backgroundColor:"#5869ff" ,display:"flex",alignItems:"center",justifyContent:"center",fontWeight:"800"}}>CRM</div>,
   // Replaces the default bot chat message container
   //botChatMessage: (props) =><MyCustomChatMessage {...props} />,
   // Replaces the default user icon
   //userAvatar: (props) => <MyCustomAvatar {...props} />,
   // Replaces the default user chat message
   //userChatMessage: (props) =><Jmp />
    // <MyCustomUserChatMessage {...props} />
 },
//  customMessages: {
//   "custom": (props) => <Jmp {...props} />
// },
};
// <Boatoptions {...props}
export default config;