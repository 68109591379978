// ChatBoat.jsx
import React from 'react';
import MyCustomMessage from './jmp.jsx';

import config from './config.jsx';
import MessageParser from './MessageParser.jsx';
import ActionProvider from './ActionProvider.jsx';
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import { useEffect, useState, useRef } from 'react';
import img from './chatimg/robot-assistant.png';
import Jmp from './jmp'; // Import Jmp component
import { useSelector,useDispatch } from 'react-redux';
import { setField} from "../app/slices";


  
const ChatBoat = () => { 
  //open chatboat by updating showchat value
 const showchat = useSelector((state)=>state.chatslice.showchat)
 const dispatch = useDispatch();  
 

  return (
    <div style={{ position: "fixed", right: "10px", bottom: "5px", zIndex: "1111",overflowX:"clip" }}>
      {!showchat && (
        <div
          style={{
            width: "100px",
            height: "100px",
            border: "5px solid #602ded",
            padding: "7px",
            borderRadius: "50%",
            position: "fixed",
            right: "120px",
            bottom: "20px",
            backgroundColor: "#1392f2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            overflow: "hidden",
          }}
          onClick={() =>dispatch(setField({ field: 'showchat', value: true }))}
        >
          <img style={{ width: "100%", objectFit: "contain" }} src={img} alt="chatboat" />
        </div>
      )}
      {showchat && (
        <>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
       
        </>
      )}
    </div>
  );
};

export default ChatBoat;
