import React, { useState, useEffect } from "react";

// Import CSS
 import "../styles/dashboardCss/contactCostumerDetails.css";
// Import api function from controller
// import { getSingleContact } from "../../controller/fetchApi";
import { getSingleContact } from "../controller/fetchApi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";


const CompanyDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const companydetails= location.state?.userData

  // Get Schedule CallId & Toekn Id
  const contactId = JSON.parse(localStorage.getItem("contactId"));
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
 
  return (
    <div className="container-fluid dashboard_create_lead_main_container">
         <span onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </span>
      {/* Company Details */}
      <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
        Company Details
      </h3>
     
      <div className="row">
        <div className="col-xl-12">
          <div className="d-xl-flex d-md-flex justify-content-around justify-content-center align-items-start">
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Name<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey",wordBreak:"break-word"}}>
                      {companydetails?.companyName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Email<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey",wordBreak:"break-word"}}>
                      {companydetails?.companyEmail}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Address<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey",wordBreak:"break-word"}}>
                      {companydetails?.companyAddress}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Company Contact<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey"}}>
                      {companydetails?.companyContact}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <h3 className="mt-2 mx-2 dashboard_leadView_company_details_heading">
        Employee Details
      </h3>
      <div className="row">
        <div className="d-flex align-items-start justify-content-between col-xl-12">
          <div className="d-xl-flex d-md-flex justify-content-around justify-content-center align-items-center">
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                     UserName<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey",wordBreak:"break-word"}}>
                      {companydetails?.userName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                     Email<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey",wordBreak:"break-word"}}>
                      {companydetails?.email}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="table-responsive mannual-table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr className="companydetail_row">
                    <th
                      scope="row"
                      className="contact_view_details_costumer_table_th"
                    >
                      Mobile Number<span style={{fontSize:"18px",fontWeight:"500"}}>:</span>
                    </th>
                    <td className="lead_view_details_table_td" style={{color:"grey"}}>
                      {companydetails?.mobile}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
