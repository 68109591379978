import React, { useState, useEffect, useRef } from "react";
import "../styles/updateProfile.css";
// Formik
import { useFormik } from "formik";
import { updateProfileFormSchema } from "../schema/FormValidation";
import {
  updateProfile,
  uploadLogo,
  uploadUserImg,
} from "../controller/fetchApi";
const UpdateProfile = ({
  getCurrentUserData,
  setupdateimg,
  setPreviewImage,
  setupdatelogo,
  setUpdateProfile
}) => {

  const abortCall  =new AbortController();
  console.log(abortCall,'abortCall abortCall abortCall abortCall')
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
  const roles = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  const [changepswd, setChangepswd] = useState(false);
  // Handle Upload Image start ----
  const [selectedFile, setSelectedFile] = useState(null);
  // handle logo
  const [selectlogo, setselectlogo] = useState(null);
  // toggle password
  const [password, setPassword] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const profileRef = useRef(null);
  const logoRef = useRef(null)
  // Form Handle & Validations
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
    },

    validationSchema: updateProfileFormSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleUploadlogo();
        if (handleUploadlogo) {
          setselectlogo(null);
        }
        await handleUploadImg();
        if (handleUploadImg) {
          setSelectedFile(null);
        }
        if (values) {
          await updateProfile(tokenId, setShowToast, values);
        }
      } catch (error) {
        console.log("Did Not Update Profile", error);
      } finally {
        setSubmitting(false);
        setTimeout(() => {
          setUpdateProfile(false)
        }, 2000);
      }
    },
  });

  // Toast Handling
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "", optmessage: "" });
    }, 3000);
  };
  if (showToast.message) {
    hideToast();
  }

  // Get Current User Details

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]));
  };
  const handleUploadImg = async () => {
    if (selectedFile) {
      await uploadUserImg(selectedFile, setShowToast, tokenId, setupdateimg);
    }
  };

  const handleLogoChange = (event) => {
    setselectlogo(event.target.files[0]);
  };
  const handleUploadlogo = async () => {
    if (selectlogo) {
      const response = await uploadLogo(
        selectlogo,
        setShowToast,
        tokenId,
        setupdatelogo
      );
    }
  };

  useEffect(() => {
    // Update Profile
    if (getCurrentUserData) {
      formik.setValues({
        ...formik.values,
        firstName: getCurrentUserData.firstName,
        lastName: getCurrentUserData.lastName,
        userName: getCurrentUserData.userName,
        email: getCurrentUserData.email,
        phone: getCurrentUserData.mobile,
      });
    }
  }, [getCurrentUserData]);

  return (
    <div className="container-xl px-4 mt-4">
      <nav className="nav nav-borders">
        <a className="nav-link active ms-0" href="#!">
          Profile Details
        </a>
      </nav>
      <hr className="mt-0 mb-4" />

      <div className="row" style={{paddingBottom:"40px"}}>
        <div className="col-xl-4">
          {/* Profile picture card*/}
          {/* <div className="card mb-4 mb-xl-0">
            <div className="card-header">Profile Picture</div>
            <div className="card-body text-center">
           
              <img
                className="img-account-profile rounded-circle mb-2"
                src={
                  getCurrentUserData?.image
                    ? `http://192.168.1.5:8080${getCurrentUserData?.image}`
                    : "http://bootdey.com/img/Content/avatar/avatar1.png"
                }
                alt=""
              />
              <div className="mb-3">
           
                <input
                  type="file"
                  className="form-control"
                  id="profilePicture"
                  accept=".jpg, .png"
                  onChange={handleFileChange}
                />
              </div>
           
              <button
                className="btn btn-primary"
                type="submit"
                onClick={handleUploadImg}
              >
                Upload
              </button>
            </div>
          </div> */}
        </div>
        <div className="col-xl-12">
          {/* Account details card*/}
          <div className=" mb-4">
            {/* <div className="card-header">Profile Details</div> */}
            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                {/* Form Group (username)*/}
                {/* <div className="mb-3">
                  <label className="small mb-1" htmlFor="inputUsername">
                    Username (how your name will appear to other users on the
                    site)
                  </label>
                  <input
                    className="form-control"
                    maxLength={15}
                    id="inputUsername"
                    type="text"
                    value={formik.values.userName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="userName"
                    placeholder="Enter username"
                  />
                  {formik.touched.userName && formik.errors.userName && (
                    <small style={{ color: "red" }}>
                      {formik.errors.userName}
                    </small>
                  )}
                </div> */}
                {/* Form Row */}
                <div className="row gx-3 mb-3 ">
                  {/* profile image */}
                  {/* <div className="col-xl-6 mb-4"  style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px"}}>
                    <div className="Upload_image_box"  onClick={()=>profileRef.current.click()}>
                      <img className="Upload_image" src={image} alt="Profile image"/>
                    </div>
                    <div className="Upload_image_button"   onClick={()=>profileRef.current.click()}>Upload image</div>
                  </div> */}
                  {/* update profile image */}
                  <div className="mb-3 col-xl-6" >
                    <div style={{ padding: "4px 8px", fontWeight: "500" }}>
                      Profile Picture
                    </div>

                    <div className="mb-3 col-xl-12 pe-2">
                      <input
                        // ref={profileRef}
                        type="file"
                        className="form-control"
                        id="profilePicture"
                        accept=".jpg,.jpeg, .png"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {/* update logo */}
                  {/* <div className="col-xl-6 mb-4"  style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"20px"}}>
                    <div className="Upload_image_box"  onClick={()=>logoRef.current.click()}>
                      <img className="Upload_image" src={image} alt="logo"/>
                    </div>
                    <div  className="Upload_image_button"  onClick={()=>logoRef.current.click()}> Upload logo</div>
                  </div> */}
                  {/* update logo */}
                
                  <div className="mb-3 col-xl-6"  >
                    <div style={{ padding: "4px 8px", fontWeight: "500" }}>
                      Upload logo
                    </div>

                    <div className="mb-3 col-xl-12 pe-2">
                      <input
                      ref={logoRef}
                        type="file"
                        className="form-control"
                        id="logoPicture"
                        accept=".jpg, .png"
                        onChange={handleLogoChange}
                        disabled={!["ADMIN","SUPERADMIN"].includes(roles)}
                      />
                    </div>
                  </div>
                  {/* Form Group (first name)*/}
                  <div className="col-md-6">
                    <label
                      className=" mb-1"
                      htmlFor="inputFirstName"
                      style={{ fontWeight: "500" }}
                    >
                      First name
                    </label>
                    <input
                      maxLength={30}
                      className="form-control"
                      id="inputFirstName"
                      type="text"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="firstName"
                      placeholder="Enter first name"
                      // disabled={["ADMIN","SUPERADMIN"].includes(roles)}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                      <small style={{ color: "red" }}>
                        {formik.errors.firstName}
                      </small>
                    )}
                  </div>
                  {/* Form Group (last name)*/}
                  <div className="col-md-6">
                    <label
                      className=" mb-1"
                      htmlFor="inputLastName"
                      style={{ fontWeight: "500" }}
                    >
                      Last name
                    </label>
                    <input
                      maxLength={30}
                      className="form-control"
                      id="inputLastName"
                      type="text"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="lastName"
                      placeholder="Enter last name"
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                      <small style={{ color: "red" }}>
                        {formik.errors.lastName}
                      </small>
                    )}
                  </div>
                </div>
                {/* Form Row        */}
                <div className="row gx-3 mb-3">
                  {/* Form Group (organization name)*/}
                  <div className="col-md-6">
                    <label
                      className=" mb-1"
                      htmlFor="inputOrgName"
                      style={{ fontWeight: "500" }}
                    >
                      Email
                    </label>
                    <input
                      disabled
                      className="form-control"
                      id="inputOrgName"
                      type="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="email"
                      placeholder="Enter email Id"
                    />
                    {formik.touched.email && formik.errors.email && (
                      <small style={{ color: "red" }}>
                        {formik.errors.email}
                      </small>
                    )}
                  </div>
                  {/* Form Group (location)*/}
                  <div className="col-md-6">
                    <label
                      className="mb-1"
                      htmlFor="inputLocation"
                      style={{ fontWeight: "500" }}
                    >
                      Phone
                    </label>
                    <input
                      className="form-control"
                      id="inputLocation"
                      type="text"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="phone"
                      placeholder="Enter your contact no"
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <small style={{ color: "red" }}>
                        {formik.errors.phone}
                      </small>
                    )}
                  </div>
                </div>

                {/* Form Row*/}
                {changepswd && (
                  <div className="row gx-3 mb-3">
                    {/* Form Group (Password)*/}
                    <div className="col-md-6 position-relative">
                      <label
                        className=" mb-1"
                        htmlFor="inputPassword"
                        style={{ fontWeight: "500" }}
                      >
                        Password
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <input
                          className="form-control"
                          id="inputPassword"
                          type={password ? "text" : "password"}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="password"
                          autoComplete=""
                          placeholder="*****************"
                        />
                        <span
                          className="position-absolute"
                          style={{ cursor: "pointer", right: "3%" }}
                          onClick={() => setPassword((prev) => !prev)}
                        >
                          {password ? "🐵" : "🙈"}
                        </span>
                      </div>
                      {formik.touched.password && formik.errors.password && (
                        <small style={{ color: "red" }}>
                          {formik.errors.password}
                        </small>
                      )}
                    </div>
                    {/* Form Group (Confirm Password)*/}
                    <div className="col-md-6 position-relative">
                      <label
                        className=" mb-1"
                        htmlFor="inputPhone"
                        style={{ fontWeight: "500" }}
                      >
                        Confirm Password
                      </label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        <input
                          className="form-control"
                          id="inputPhone"
                          type={confirm ? "text" : "password"}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="confirmPassword"
                          autoComplete=""
                          placeholder="*****************"
                        />
                        <span
                          style={{
                            position: "absolute",
                            cursor: "pointer",
                            right: "3%",
                          }}
                          onClick={() => setConfirm((prev) => !prev)}
                        >
                          {confirm ? "🐵" : "🙈"}
                        </span>
                      </div>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <small style={{ color: "red" }}>
                            {formik.errors.confirmPassword}
                          </small>
                        )}
                    </div>
                  </div>
                )}
                {/* Save changes button*/}
                <div
                  style={{
                    marginTop:'50px',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Save changes
                  </button>
                  <span
                    className="change_passward"
                    onClick={() => setChangepswd((prev) => !prev)}
                  >
                    {!changepswd ? "Change Password" : "Do not change Password"}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Toast */}
      {showToast.message && (
        <div
          className="toast-container position-fixed  end-0 p-3 "
          style={{ top: "5%" }}
        >
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <strong className="me-auto">
                {/* Form Submitted Successfully */}
                {showToast.success ? showToast?.optmessage : "Error"}
              </strong>
              <button
                type="button"
                className="btn-close"
                onClick={() =>
                  setShowToast({ success: false, message: "", optmessage: "" })
                }
              />
            </div>
            <div className="toast-body">{showToast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateProfile;
