import React, { useState, useEffect } from "react";
// CSS
import "../../styles/dashboardCss/leadCostumerDetails.css";
// React Icons
import { MdVerified } from "react-icons/md";
import { MdCancel } from "react-icons/md";
// Import Toast
import Toast from "../../components/Toast";
// Import api function from controller
import {
  getSingleLead,
  verifyLeads,
  rejectedLeads,
} from "../../controller/fetchApi";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";


const LeadCostumerDetails = () => {
  const navigate = useNavigate()
  // Start Toast Code-------
  const [showToast, setShowToast] = useState({ success: false, message: "",optmessage:"" });
  // hise toast
   if(showToast.message){
    setTimeout(() => {
      setShowToast({ success: false, message: "" ,optmessage:""})
    }, 3000);
   }

  // Get User details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("leadId"));
  const leadId = userIdTokenData;
  const userTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userTokenData?.data?.token;
  const [getSingleLeadData, setSingleLeadData] = useState([]);
  const [uploading,setuploading] = useState(false)

  //  Verified lead
  const handleVerifyLeads = async (leadId) => { setuploading(true)
    const response =  await verifyLeads(leadId, setShowToast, tokenId);
   
    if(response){
      setTimeout(() => {
        navigate("/leads");
        setuploading(false);
      },1000);
    }
  };

  // Rejected lead
  const handleRejectedLeads = async (leadId) => { setuploading(true);
    await rejectedLeads(leadId, setShowToast, tokenId);
    if(rejectedLeads){
      setTimeout(() => {
        setuploading(false);
        navigate("/leads")
      }, 1000);
    }
  };

  useEffect(() => {
    getSingleLead(leadId, tokenId).then((res) => {
      setSingleLeadData(res);
    });
  }, [leadId, tokenId]);

  return (
    <>
      <div className="container-fluid dashboard_create_lead_main_container">
      <span onClick={()=>navigate(-1)}>
                  <IoArrowBackCircleOutline  className="_back_button" style={{width:"60px"}} />
                </span>
        <h2 className="mt-3 mx-2 dashboard_leadView_details_heading">
          Lead Information
        </h2>
        <p className="mx-2 dashboard_leadView_details_heading_second">
          Lead Id : LI-{getSingleLeadData?.id}
        </p>
        {/* Cosutmer Details */}
        <div className="row my-3 mx-2 lead_view_details_costumer_details" >
          {/* <div className="col-xl-3 col-md-3">
            <div className="lead_costumer_details_firstRow_left_div">
              <div className="lead_costumer_details_firstRow_left_imgDiv">
                <img
                  src="https://wac-cdn.atlassian.com/dam/jcr:ba03a215-2f45-40f5-8540-b2015223c918/Max-R_Headshot%20(1).jpg?cdnVersion=1543"
                  alt="costumer-img"
                  className="img-fluid lead_costumer_details_firstRow_left_img"
                />
              </div>
              <div className="lead_view_details_costumer_name_email">
                <p className="lead_view_details_costumer_name">
                  {getSingleLeadData.firstName}
                </p>
                <p className="lead_view_details_costumer_email">
                  {getSingleLeadData.email}
                </p>
              </div>
            </div>
          </div> */}
          <div className="col-xl-12 col-md-8">
            <div className="d-xl-flex d-md-flex  gap-5 align-items-start">
              <div className="table-responsive col-xl-3">
                <table className="table table-borderless">
                  <tbody>
                    <tr >
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Lead Owner
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td" style={{wordBreak:"break-word"}}>
                        {getSingleLeadData.leadOwner}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Mobile Number
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.mobile}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Lead Status
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData?.leadStatus}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Lead Service
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData?.leadService}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive col-xl-3"  style={{overflowX:"visible"}}>
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Email
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.email}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Phone
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.secondaryMobile}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Lead Source
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.leadSource}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Company Details */}
        <h3 className=" mx-2 dashboard_leadView_company_details_heading" style={{marginTop:"40px"}}>
          Company Details
        </h3>
        <div className="row" style={{paddingLeft:'20px',marginTop:"20px"}}>
          <div className="col-xl-12">
            <div className="d-xl-flex d-md-flex gap-5 flex-wrap  align-items-start">
              <div className="table-responsive col-xl-3">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Annual Revenue
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData?.annualRevenue}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Company Contact
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData?.companyContact}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        District
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData?.district}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive col-xl-3">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Company Name
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.companyName}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Secondary Contact
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.secondaryContact}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        State
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.state}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="table-responsive col-xl-3">
                <table className="table table-borderless">
                  <tbody>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Company Email
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td" style={{wordBreak:"break-word"}}>
                        {getSingleLeadData.companyEmail}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        City
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.city}
                      </td>
                    </tr>
                    <tr>
                      <th
                        scope="row"
                        className="lead_view_details_costumer_table_th"
                      >
                        Country
                      </th><span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
                      <td className="lead_view_details_table_td">
                        {getSingleLeadData.country}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* Description */}
        <h4 className="my-2 mx-2 dashboard_leadView_company_details_heading">
          Description<span style={{fontWeight:"500",fontSize:"21px"}}>:</span>
        </h4>
        <div className="row" style={{paddingLeft:"20px"}}>
          <div className="col-xl-12 my-1 mx-2">
            <p className="lead_view_details_description">
              {getSingleLeadData.description}
            </p>
          </div>
        </div>
      </div>
      {/* Btns */}
      <div>
        {getSingleLeadData?.leadType === "verified" ? (
          ""
        ) : getSingleLeadData?.leadType === "unverified" ? (
          <div className=" view_leads_details_btn_div">
            <button 
            disabled={uploading}
              className="leads_verify_btn"
              onClick={() => handleVerifyLeads(getSingleLeadData?.id)}
            >
              <MdVerified className="leads_verify_btn_icon" />
              Verify Leads
            </button>

            <button
              disabled={uploading}
              className="leads_reject_btn"
              onClick={() => handleRejectedLeads(getSingleLeadData?.id)}
            >
              <MdCancel className="leads_verify_btn_icon" />
              Reject Leads
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </>
  );
};

export default LeadCostumerDetails;
