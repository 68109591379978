import * as Yup from "yup";

// Signup Form Schema
export const signupFormSchema = Yup.object({
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name"
    )
    .max(20, "Maximum 20 character are allowed")
    .trim(),
  lastName: Yup.string()
    .required("Last name is required")
    .matches(/^[a-zA-Z]+(\s[A-Za-z]+)*?$/, "Please enter valid last name")
    .max(20, "Lastname must be 20 characters or less")
    .trim(),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .matches(
      /^[a-z0-9._+-]{1,64}@[a-z0-9.-]+\.(?:in|com)$/,
      "Please enter valid email id."
    )
    .trim(),
  userName: Yup.string()
    .required("Username is required")
    .matches(/^[a-zA-Z0-9]+/, "Create username with alphanumeric character")
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(/^[a-zA-Z0-9@]{6,15}/, "Create strong password using alphabets,digits and @.")
    .min(6,"Password length should be atleast 6 character. ")
    .max(15,"Password length should be maximum 15 character. ")
    .trim(),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^[6-9][0-9]{9}$/, "Enter correct number")
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});

// Update Profile Form Schema
export const updateProfileFormSchema = Yup.object({
  firstName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .min(1, "atleast one character is required")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]+(\s[A-Za-z]+)?$/, "only alphabets can be entered.")
    .max(50, "Lastname must be 50 characters or less")
    .trim(),
  // email: Yup.string().email("Invalid email").required("Email is required"),
  userName: Yup.string()
    .matches(/^[a-zA-Z]+[a-zA-Z0-9]+/, "username can contain alphabets and digits.")
    .max(15,'Enter maximum 15 characters.')
    .min(3,'Enter minimum 3 characters.')
    .trim(),
  email: Yup.string()
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .matches(
      /^[a-zA-Z0-9_\-\$.+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  phone: Yup.string()
    .matches(/^[0-9]{10}$/, "Number can be of 10 digits.")
    .trim(),
});

// Login Form Schema
export const loginFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
});

// Forgot Password Form Schema
export const forgotPasswordFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
});

// Reset Password Form Schema
export const resetPasswordFormSchema = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
      "password should have 6 to 15 alphabets or digits"
    )
    .trim(),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

// OTP Verification
export const otpVerificationSchema = Yup.object().shape({
  digit1: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 1 must be a single digit"),
  digit2: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 2 must be a single digit"),
  digit3: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 3 must be a single digit"),
  digit4: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "Digit 4 must be a single digit"),
});

//MPIN validation
export const pinValidation = Yup.object().shape({
  dig1: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig2: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig3: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
  dig4: Yup.string()
    .required("*")
    .matches(/^[0-9]{1}$/, "must be a number."),
});

// Create Lead Schema Vallidation
export const registerSchema = Yup.object({
  leadOwner:Yup.string()
  .required("Lead Owner is required")
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
    "Please enter valid owner name."
  )
  .trim()
  .max(100, "Name must be 100 characters or less"),
  firstName: Yup.string()
  .required("First name is required")
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
    "Please enter valid name."
  )
  .trim()
  .max(100, "Name must be 100 characters or less"),
  lastName: Yup.string()
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
    "Please enter valid name."
  )
  .trim()
  .max(100, "Name must be 100 characters or less"),
  email: Yup.string()
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email id"
  )
  .trim(),
  emailAlt: Yup.string()
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email id."
  )
  .trim(),
  mobile:Yup.string()
  .required("Mobile Number is required")
  .matches(/^[6-9][0-9]{9}$/, "Please enter valid mobile no")
  .trim(),
  mobileAlt:Yup.string()
  .matches(/^[6-9][0-9]{9}$/, "Please enter valid mobile no")
  .notOneOf([Yup.ref('mobile')], 'Please enter different mobile no')
  .trim(),
  leadSource:Yup.string(),
  leadStatus: Yup.string(),
  // annualRevenue:Yup.number()
  // .typeError("Revenue must be a number")
  // .test(
  //   "is-decimal",
  //   "Revenue must have at most 2 decimal places",
  //   (value) => {
  //     if (value === null || value === undefined || value === "") {
  //       return true; // Skip validation if the value is empty
  //     }
  //     return /^\d+(\.\d{1,2})?$/.test(value);
  //   }
  // ),
  companyName:Yup.string()
  .matches(
    /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*?$/,
    "Please enter valid company name."
  )
  .trim()
  .max(100, "Name must be 100 characters or less"),
  companyEmail: Yup.string()
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email id."
  )
  .trim(),
  companyContact:Yup.string()
  .matches(/^[0-9]{10}$/, "enter valid contact no.")
  .trim(),
  secondaryContact:Yup.string()
  .matches(/^[0-9]{10}$/, "enter valid contact no.")
  .trim(),
  city: Yup.string()
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)?$/,
    "Please enter valid city"
  )
  .max(50, "Please enter valid city")
  .trim(),
  district:  Yup.string()
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)?$/,
    "Please enter valid district name"
  )
  .max(50, "Please enter valid district name")
  .trim(),
  state: Yup.string()
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)?$/,
    "Please enter valid state"
  )
  .max(50, "Please enter valid state")
  .trim(),
  country: Yup.string()
  .matches(
    /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
    "Please enter valid country"
  )
  .max(50, "Please enter valid country")
  .trim(),
  description: Yup.string()
  .max(500, "Description can't be more than 500 characters")
  .trim(),
  //-----------------------
  phone:Yup.string()
  .matches(/^[1-9][0-9]{7,14}$/, "Please enter valid contact no")
  // .notOneOf([Yup.ref('mobile'),Yup.ref('mobileAlt')], 'Please enter different phone no')
  .trim(),
  phoneAlt: Yup.string()
  .matches(/^[0-9]{10}$/, "Please enter valid contact no")
  .trim(),

  subject:Yup.string()
  .matches(/^[a-zA-Z]+[a-zA-Z0-9]+$/,"Please enter valid subject.")
  .trim(),
  pinCode:Yup.string()
  .matches(/^[1-9][0-9]{5}/,'Please enter valid pincode.')
  .trim(),
  address:Yup.string()
  .max(250, "Address can be upto 250 characters ")
  .matches(/^[a-zA-z0-9]+[A-Za-z0-9._%+\-\s]{2,}$/, "Enter correct address")
  .trim(),
  productName: Yup.string()
  .matches(/^[a-zA-Z]+[a-zA-Z0-9]+$/,"Please enter valid product.")
  .trim(),
  callDuration:Yup.string()
  .matches(/^[0-9-]{1,4}/,'Please enter valid time duration.'),
  leadType:Yup.string()
  .required("Please select lead type."),
});

// Update Lead Schema validation
export const updateRegisterSchema = Yup.object({
  leadOwner: Yup.string()
    .required("Lead Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less")
    .min(5, "name should be minimum 5 character."),
  firstName: Yup.string()
    .required("First name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less"),
  lastName: Yup.string()
    .required("Last name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered without using space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  emailAlt: Yup.string()
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  mobileNumber: Yup.string()
    .required("Mobile Number is required")
    .matches(/^[0-9]{10}$/, "enter valid mobile no.")
    .trim(),
  secondaryMobileNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "enter valid mobile no.")
    .trim(),
  leadSource: Yup.string(),
  leadStatus: Yup.string(),
  annualRevenue: Yup.number()

    .typeError("Revenue must be a number")
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  queryMessage: Yup.string()
    .matches(/^[A-Za-z0-9]+(\s[A-Za-z0-9]+)?(\s[A-Za-z0-9]+)?(\s[A-Za-z0-9]+)?(\s[A-Za-z0-9]+)?$/, "Please enter valid query.")
    .trim(),
  companyName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(100, "Name must be 100 characters or less")
    .trim(),
  companyEmail: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  companyContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  secondaryContact: Yup.string()
    .matches(/^[0-9]{8,15}$/, "Number can be of 10 digits.")
    .trim(),
  city: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "City must be 50 characters or less")
    .trim(),
  district: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "District must be 50 characters or less")
    .trim(),
  state: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "State must be 50 characters or less")
    .trim(),
  country: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "State must be 50 characters or less")
    .trim(),
  description: Yup.string()
    .min(20, "Description must be at least 20 characters")
    .max(250, "Description can't be more than 500 characters")
    .trim(),
    phone:Yup.string()
    .matches(/^[1-9][0-9]{9}$/,"Please enter valid number.")
    .trim() ,
    phoneAlt: Yup.string()
    .matches(/^[1-9][0-9]{9}$/,"Please enter valid number.")
    .trim(),
    emailAlt:Yup.string()
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim() ,
    subject:Yup.string()
    .matches(/[a-zA-Z0-9]+[a-zA-Z0-9]+$/,'Please enter a valid subject.')
    .trim(),
    pinCode: Yup.string()
    .matches(/^[1-9]+[0-9]+$/,"Please enter valid pincode.")
    .trim(),
    address:Yup.string()
    .max(250, "Address can be upto 250 characters ")
    .matches(/^[a-zA-z0-9]+[A-Za-z0-9._%+\-\s]{2,}$/, "Enter correct address")
    .trim() ,
    productName:Yup.string()
    .matches(/^[a-zA-Z0-9]+$/,"Please enter valid product name.")
    .trim() ,
    callDuration:Yup.string()
    .matches(/^[0-9:]{1,6}/,"Please enter correct call duration.") ,
});
// create company validation
export const CreateCompanySchema = Yup.object({
  firstName:Yup.string()
  .required("first name is required.")
  .matches(/^[A-Za-z]+(\s[a-zA-Z]+)*?$/,'Please Enter Valid Name')
  .max(30,'Enter maximum 30 character'),
  lastName:Yup.string()
  .required('last name is required')
  .matches(/^[A-Za-z]+(\s[a-zA-Z]+)*?$/,'Please Enter Valid Name')
  .max(30,'Enter maximum 30 character'),
  userName:Yup.string()
  .required("username is required.")
  .matches(/^[a-zA-Z0-9]{6,30}$/,'create username with alphabets or digits.')
  .label('Enter 6 to 30 characters for username.')
  .max(30,'Enter maximum 30 character')
  .min(6,'Please Enter First Name'),
  password:Yup.string()
  .required("Password is required")
  .matches(
    /^[a-zA-Z0-9_\-\$\.\+#]{6,15}$/,
    "password should have 6 to 15 alphabets or digits"
  )
  .trim(),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("password"), null], "Passwords must match")
  .required("Password is required")
  .trim(),
  email: Yup.string()
  .required("Email is required")
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email id."
  )
  .trim(),
  mobile:Yup.string()
  .required("Mobile Number is required")
  .matches(/^[6-9][0-9]{9}$/, "Please enter valid name")
  .trim(),
  companyName: Yup.string()
  .required('company name is required.')
  .matches(
    /^[A-Za-z]+(\s[A-Za-z0-9]+)*?$/,
    "Please enter valid company."
  )
  .max(100, "maximum 50 character can be entered")
  .trim(),
  companyEmail:Yup.string()
  .required("Email is required")
  .matches(
    /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
    "please enter valid email"
  )
  .trim(),
  companyContact: Yup.string()
  .required("Company contact is required")
  .matches(/^[6-9][0-9]{9}$/, "Please enter valid no")
  .trim(),
  companyAddress:Yup.string()
  .required("Address is required")
  .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct address")
  .trim(),
  // numberOfOffice:Yup.string()
  // .matches(/[1-9][0-9]{0,4}/,'please enter valid number.')
  // .label(' enter maximum 10 digits.'),
  // numberOfDepartment:Yup.string()
  // .matches(/[1-9][0-9]{0,4}/,'please enter valid number.')
  // .label(' enter maximum 10 digits.'),
  // numberOfDesignation:Yup.string()
  // .matches(/[1-9][0-9]{0,4}/,'please enter valid number.')
  // .label(' enter maximum 10 digits.'),
});
// Contact Schema Validation
export const ContactFormSchema = Yup.object({
  companyName: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?(\s[A-Za-z]+)?(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "Please enter a valid company name."
    )
    .min(2,'Please enter valid company name.')
    .max(100, "Name must be 100 characters or less")
    .trim(),
  companyEmail: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-z0-9\._\+\-]+@[a-z0-9.-]+\.(?:in|com)$/,
      "please enter valid email id."
    )
    .trim(),
  companyContact: Yup.string()
    .required("Company contact is required")
    .matches(/^[0-9]{10}$/, "Please enter valid mobile no.")
    .trim(),
  address: Yup.string()
    .required("Company address is required")
    .max(250, "Address can be upto 250 characters ")
    .matches(/^[a-zA-z0-9]+[A-Za-z0-9._%+\-\s]{2,}$/, "Enter correct address")
    .trim(),
  leadId: Yup.string()
    .required("lead id is required.")
    .matches(/^[0-9]+$/, "lead id should contain digits.")
    .trim(),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(250, "Description can't be more than 500 characters")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
});

// Account Schema Validation
export const accountFormSchema = Yup.object({
  accountOwner: Yup.string()
    .required("Lead Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim()
    .max(100, "Name must be 100 characters or less")
    .min(5, "name should be minimum 5 character."),
  accountName: Yup.string()
    .trim()
    .required("Account name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .max(50, "Name must be 50 characters or less"),
  accountSite: Yup.string()
    .trim()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .required("Account site is required")
    .max(50, "site should not have more than 50 character."),
  parentAccount: Yup.string()
    .matches(/^[0-9]+$/, "account no is a number.")
    .required("Parent account is required")
    .trim(),
  accountNumber: Yup.string()
    .matches(/^[0-9]+$/)
    .required("Account number is required")
    .trim(),
  aadharNumber: Yup.string()
    .matches(/^[0-9]{12}$/, "aadhaar number must be number.")
    .required("Aadhar number is required")
    .trim(),
  panCardNumber: Yup.string()
    .matches(
      /^[A-Za-z0-9]{10}$/,
      "only alphabets and no. can be entered without using space."
    )
    .required("PAN Card number is required")
    .trim(),
  accountType: Yup.string().required("Account type is required"),
  industry: Yup.string().required("Industry is required"),
  annualRevenue: Yup.number()
    .positive()
    .typeError("Revenue must be a number")
    .required("Annual revenue is required")
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  address: Yup.string()
    .required("Address is required")
    .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct address")
    .trim(),
  leadId: Yup.string()
    .matches(/^[0-9]{0,10}$/, "lead id must be number.")
    .required("Required Lead Id ")
    .trim(),
  billingAddress: Yup.string()
    .required("billingaddress is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct billingaddress"
    )
    .trim(),
  billingCity: Yup.string()
    .required("billingCity is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?$/,
      "only alphabets can be entered with single space."
    )
    .trim(),
  billingState: Yup.string()
    .required("billingState is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct billingState"
    )
    .trim(),
  billingCode: Yup.string()
    .matches(/^[0-9]+$/, "billingCode is a number.")
    .required("Billing code is required")
    .trim(),
  shippingStreet: Yup.string()
    .required("shippingStreet is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingStreet"
    )
    .trim(),
  shippingCity: Yup.string()
    .required("shippingCity is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingCity"
    )
    .trim(),
  shippingState: Yup.string()
    .required("shippingState is required")
    .matches(
      /^[A-Za-z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingState"
    )
    .trim(),
  shippingCode: Yup.string()
    .matches(/^[0-9]+$/, "shippingCode is a number.")
    .required("Shipping code is required")
    .trim(),
  shippingAddress: Yup.string()
    .required("shippingAddress is required")
    .matches(
      /^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/,
      "Enter correct shippingAddress"
    )
    .trim(),
  dateOfIssue: Yup.date().required("Date of Issue is required"),
  dateOfBilling: Yup.date().required("Date of date of billing is required"),
  dateOfShipment: Yup.date().required("Date of date of shipment is required"),
  description: Yup.string()
    .required("Description is required")
    .min(20, "Description must be at least 20 characters")
    .max(500, "Description can't be more than 500 characters")
    .matches(
      /^[A-Za-z0-9\s.,'!?-]+$/,
      "Description can only contain letters, numbers, and common punctuation"
    )
    .trim(),
});

// Task Schema Validation
export const TaskFormSchema = Yup.object({
  taskOwner:Yup.string(),
      taskSubject:Yup.string()
      .matches(/^[a-zA-Z]{2,50}$/,'Please Enter valid subject.'),
      dueDate: Yup.date()
      .required("Due date is required")
      .min(new Date(), "Due date cannot be in the past"),
      contact:Yup.string()
      .required("Contact is required")
      .matches(/^[6-9][0-9]{9}/,'Please enter valid number.'),
      status:Yup.string()
      .required("Status is required."),
      priority:Yup.string()
      .required("Priority is required."),
      leadId:Yup.string()
      .required('Lead id is required.')
      .matches(/^[1-9][0-9]{1,10}$/,'Please enter valid lead id.'),
      description:Yup.string()
      .matches(/^[a-zA-Z][A-Za-z0-9-+@#$%]+$/,"Enter valid character."),
      accountType:Yup.string()
      .required("Please select valid type."),
     
});

// Meeting Form Schema Validation
export const MeetingFormSchema = Yup.object({
  host: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name."
    )
    .min(1, "Please enter name.")
    .max(50, "maximum 50 character can be entered")
    .required("Host is required")
    .trim(),
  title: Yup.string()
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid title."
    )
    .min(1, "Please enter title.")
    .max(50, "maximum 50 character can be entered")
    .required("Title is required")
    .trim(),
  address: Yup.string()
    .matches(/^[a-zA-Z0-9]+[A-Za-z0-9\._%\+\-\s]{2,}$/, "Enter correct Address")
    .required("Address is required")
    .trim(),
  date: Yup.date()
    .required("Date is required")
    .min(new Date(), "Date cannot be in the past"),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,10}$/, "lead id is a number upto 10 digits.")
    .trim(),
});

// Schedule Call Schema Validation
export const ScheduleCallSchema = Yup.object({
  relatedToName:Yup.string()
  .required('Please enter name.')
  .matches(/^[a-zA-Z]+(\s[A-Za-z0-9]+)*?$/,"Please enter valid name.")
  .min(1,'Please enter name.'),
  callToName:Yup.string()
  .required('Please enter name.')
  .matches(/^[a-zA-Z]+(\s[A-Za-z0-9]+)*?$/,"Please enter valid name.")
  .min(1,'Please enter name.'),
  callTo: Yup.string()
 .required("Call To is required"),
  relatedTo: Yup.string()
  .required("Related To is required"),
  callType: Yup.string(),
  callStatus: Yup.string()
  .matches(/^[a-zA-Z]+(\s[A-Za-z0-9]+)*?$/,'Please enter valid status.')
  .trim(),
  callOwner: Yup.string()
    .required("Call Owner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name."
    )
    .min(1, "please enter valid name")
    .max(30, "Call Owner must be 30 characters or less")
    .trim(),
  subject: Yup.string()
    .required("Subject is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)?[A-Za-z\s0-9]+$/,
      "Please enter valid subject."
    )
    .min(1, "Please enter subject.")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  reminder: Yup.string(),
  leadId: Yup.string()
    .required("Required Lead Id ")
    .matches(/^[0-9]{0,6}$/, "lead id is a numner."),

  callPurpose: Yup.string().required("Call Purpose is required"),
  callAgenda: Yup.string().required("Call Agenda is required")
  .matches(/[a-zA-Z]+[a-zA-Z\s]*/,'Please enter valid agenda.'),
  callStartTime: Yup.date().required("Call Start Time is required"),
});

// Log Call Schema Validation
export const LogCallSchema = Yup.object({
  callTo: Yup.string()
    .required("Call To is required"),
  relatedTo: Yup.string().required("Related To is required"),
  callType: Yup.string().required("Call Type is required"),
  callStatus: Yup.string()
    .matches(/^[a-zA-Z]+(\s[A-Za-z0-9]+)*$/, "Please enter valid call status.")
    .trim(),
  callDuration: Yup.string()
     .matches(/^[0-9:-]{1,6}/,'Please enter valid call duration.'),
  subject: Yup.string()
    .required("Subject is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*$/,
      "Please enter valid subject."
    )
    .min(1, "Please enter subject.")
    .max(50, "Subject must be 50 characters or less")
    .trim(),
  callResult: Yup.string().required("Call result is required"),
  callPurpose: Yup.string().required("Call Purpose is required"),
  callAgenda: Yup.string().required("Call Agenda is required")
  .matches( /^[A-Za-z]+(\s[A-Za-z]+)*$/,"Please enter valid agenda."),
  callStartTime: Yup.date().required("Call Start Time is required"),
  description:Yup.string()
  .matches(/^[a-zA-Z]+(\s[a-zA-Z0-9]*)*$/,'Please enter valid description.'),
});

// DealFormSchema
export const DealFormSchema = Yup.object({
  dealOwner: Yup.string()
    .required("dealOwner is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name."
    )
    .min(1, "Please enter valid name.")
    .max(30, "maximum 30 character can be entered")
    .trim(),
  dealName: Yup.string()
    .required("Deal Name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name."
    )
    .min(1, "Please enter valid name.")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  amount: Yup.string()
    .required("Amount is required")
    .matches(/^[0-9]{1,12}$/,'Please enter valid amount.'),
  closingDate: Yup.date().required("Closing date is required")
  .min(new Date(), 'Please pick a future date.'),
  accountName: Yup.string()
    .required("Account Name is required")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid account"
    )
    .min(1, "Please enter account name")
    .max(50, "maximum 50 character can be entered")
    .trim(),
  stage: Yup.string().required("Stage is required"),
  type: Yup.string().required("Type is required"),
  nextStep: Yup.string()
    .required("Next Step is required")
    .matches( /^[A-Za-z]+(\s[A-Za-z]+)*?$/,'Please enter valid data.')
    .max(50, "Only 50 characters are allowed."),
  expectedRevenue: Yup.number()
    .required("Expected Revenue is required")
    .typeError("Revenue must be a number")
    .positive()
    .test(
      "is-decimal",
      "Revenue must have at most 2 decimal places",
      (value) => {
        if (value === null || value === undefined || value === "") {
          return true; // Skip validation if the value is empty
        }
        return /^\d+(\.\d{1,2})?$/.test(value);
      }
    ),
  leadSource: Yup.string().required("Lead Source is required."),
 
  contactName: Yup.string()
  .required("Contact name is required.")
    .matches(
      /^[A-Za-z]+(\s[A-Za-z]+)*?$/,
      "Please enter valid name."
    )
    .min(1, "Please enter name.")
    .max(50, "maximum 50 character can be entered"),
  leadId: Yup.string()
    .required("Lead Id is required.")
    .matches(/^[0-9]{0,10}$/, "Please enter valid lead id."),
    description:Yup.string()
    .matches( /^[A-Za-z]+(\s[A-Za-z0-9';:,-_+=(&%$#@!)]+)*?$/,'Please enter correct description.'),
});
