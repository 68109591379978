import Modal from 'react-bootstrap/Modal';
import Mewpin from './mpin';


const Deleteuserconfirmation = ({show,setShow,handleDelete,sourceName='user' })=>{
  
  
  const handleClose = () => setShow(false);
 

  return (
    <Modal show={show} onHide={handleClose} animation={true}>
    
    <Modal.Header closeButton>
      <Modal.Title style={{color:"#696b6a"}}>Delete {sourceName}</Modal.Title>
    </Modal.Header>

    <Mewpin handleDelete={handleDelete}/>

  </Modal>
  );
}


export default Deleteuserconfirmation;