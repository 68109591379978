import React, { useState, useEffect } from "react";
import bootstrap from "bootstrap";
import UpdateRoleAndPermission from "../pages/UpdateRoleAndPermission";
// React Router
import { Link } from "react-router-dom";
// React Icons
import { TfiWrite } from "react-icons/tfi";
import { TiUserDelete } from "react-icons/ti";
// Import Toast
import Toast from "./Toast";
import { FaRegEye } from "react-icons/fa";
// Controller
import {
  getSingleUserPermission,
  deleteUserByAdmin,
} from "../controller/fetchApi";
import Deleteuserconfirmation from "./deleteuserconfirmation";
import activity_icon from '../images/activity_icon.png'
import delete_icon from '../images/delete_icon.png'
import Loader from "../pages/Loader";

const CreatedUserTable = ({

  tblHead,
  data,
  getAllUser,
  redirectLink,
  setcurrdata,
  loading
}) => {
  // Toast Message Code
  const [showToast, setShowToast] = useState({ success: false, message: "" ,optmessage:""});
  if(showToast.message){
    setTimeout(() => {
      setShowToast({ success: false, message: "" ,optmessage:""})
    }, 3000);
  }
 
  //  const closeToast = ()=>{
  //   setShowToast({ success: false, message: "" })
  //  }
  //  if(showToast.message){
  //   setTimeout(() => {
  //     closeToast();
  //   }, 4000);
  //  }
  // update user
  const [updateUser, setupdateUser] = useState(null);

  // Token And Users Data
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;

  // Update Role And Permissions
  const [defaultValue, setDefaultValue] = useState();

  const [currentUser, setCurrentUser] = useState();

  // const handleUpdateRoleAndPermission = async (user) => {

  //   try {
  //     const roleAndPermissions = await getSingleUserPermission(
  //       user?.id,
  //       tokenId
  //     );
  //     console.log("roleAndPermissions",roleAndPermissions)
  //     setCurrentUser(user);
  //     setDefaultValue(roleAndPermissions);
  //   } catch (error) {
  //     console.log("Created User Update getModulePermissions :", error);
  //   }
  // };
  //open Role and update modal
  // const [updatemodal, setupdatemodal] = useState(false);
  //user id for deletion and confirmation for deletion
  const [deluser, setdeluser] = useState("");
  const [show, setshow] = useState(false);
  // Handle User Delete
  const handleUserDelete = async (pin) => {
    try {
      const result = await deleteUserByAdmin(tokenId, deluser, setShowToast, pin);
      if (result?.data?.status === 200) {
        getAllUser();
      }
    } catch (error) {
      console.log("user not deleted in superAdmin section 2", error);
    } finally {
      setshow(false);
    }
    //}
  };
  //user delete
  // const deleteuser = (userid)=>{
  //   let confirm = confirm("Are you sure? You want to delete this user.")
  //   if(confirm){
  //     handleUserDelete(user?.id)
  //   }
  // if(confirm("Are you sure? You want to delete this user.")){
  //   handleUserDelete(user?.id)
  // }
  //}

  // modal control through state
  // const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   if (showModal) {
  //     document.getElementById('updateRoleAndPermissionModal').style.display="block";
  //   } else {
  //     document.getElementById('updateRoleAndPermissionModal').style.display="none";
  //   }
  // }, [showModal]);

  const [updatemodalUser, setupdatemodalUser] = useState(null);

  return (
    <div className="LeadRightSectionTable_body table-responsive">
      
      <table className="table  ">
        <thead >
          <tr className="table-danger dashboard_section1_tableHead_tr th-design" >
            <th scope="col" className="text-center" >{tblHead.firstHead}</th>
            <th scope="col" className="text-left">
              {tblHead.secondHead}
            </th>
            <th scope="col" className="text-left" >
              {tblHead.thirdHead}
            </th>
            <th scope="col" className="text-left " >
              {tblHead.seventhHead}
            </th>
            <th scope="col" className="text-left " > 
              {tblHead.fourthHead}
            </th>
            <th scope="col" className="text-center col-xl-1" >{tblHead.fifthHead}</th>
            <th scope="col" className="text-center col-xl-1">
              {tblHead.sixthHead}
            </th>
            <th scope="col" className="text-center col-xl-1" >
              {tblHead.eighthHead}
            </th>
            <th scope="col" className="text-center col-xl-1" >
              {tblHead.ninthHead}
            </th>
            {/* <th scope="col">{tblHead.fifthHead}</th> */}
          </tr>
        </thead>
        {loading ? (
        <Loader />
      ) : (
        <tbody className="dashboard_section1_tableBody">
          {data && data?.length > 0 ? (
            data?.map((user, index) =>(
              <tr key={index} className="th-design">
                <td className="text-center th-design" >{index + 1}</td>
                <td className="text-left col-xl-2" style={{ wordBreak: "break-word"}} ><div>{`${user?.firstName}  ${user?.lastName}`}</div></td>
                <td className="text-left col-xl-2" style={{ wordBreak: "break-word"}}>{user?.email.slice(0,3)}xxxxxxxxxxmail.com </td>
                <td className="text-left" >{user?.role || "Not Assigned"}</td>
                <td className="text-left" >{user?.mobile.slice(0,2)}xxxxxxxxxx{user?.mobile.slice(8,10)}</td>
                <td className="text-center col-xl-1" >
                  <Link to="/dashboard" state={{ userId: user?.id }}>
                    <FaRegEye className="fs-3 super_admin_section2_table_icon" />
                  </Link>
                </td>
                <td className="text-center" >
                  {/* hhhhhhhhhh */}
                  {!user.role && (
                    <Link title="Assign Role and Permission"
                      to={redirectLink}
                      className="Link-button-leads col-xl-1"
                      state={{ userData: user }}
                    >
                      <TfiWrite
                        className="fs-4"
                        style={{ color: "#0d6efd" }}
                      />
                    </Link>
                  )}
                  {/* hhhhhhhhhhhhh */}
                  &nbsp; &nbsp;
                  {user.role && (
                    <Link title="Update Role and Permission"
                      className="Link-button-leads"
                      // data-bs-toggle="modal"
                      // data-bs-target="#updateRoleAndPermissionModal"
                    >
                      {/* <TfiWrite
                  className="fs-4"
                  style={{ color: "rgba(145, 155, 250, 1)", marginRight:'15px'}}
                /> */}
                      <button
                        className="btn btn-outline-primary btn-sm"
                        onClick={() => { 
                          //handleUpdateRoleAndPermission(user);
                          setupdateUser(user);
                        }}
                      >
                        Update
                      </button>
                      {/* <BsPencilSquare
                      className="fs-4"
                      style={{ color: "rgba(145, 155, 250, 1)" }}
                      onClick={() => {
                        handleUpdateRoleAndPermission(user);
                      }}
                    /> */}
                    </Link>
                  )}
                </td>
                <td className="text-center col-xl-1">
                  <TiUserDelete title="Delete User"
                  //  src={delete_icon}
                  //    width="25px"
                    className="fs-3 created_user_table_action_delete"
                    //  onClick={deleteuser}
                    onClick={() => {
                      //handleUserDelete(user?.id)
                      setshow(true);
                      setdeluser(user?.id);
                    }}
                  />
                </td>
                <td className="text-center col-xl-1">
                  <Link to="/userLogs" state={{userid:user?.id}}>
                  <img title="Delete User"
                  width="25px"
                  // style={{backgroundColor:"#eb4034"}}
                    src={activity_icon}
                    className="fs-3 created_user_table_action_delete"
                  />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">No users at this time</td>
            </tr>
          )}
        </tbody>
          )}
      </table>

      {/* Update Role And Permission Modal */}
      {/* {updateUser && (
        <>
          <div
            className="modal modal_bar fade modal-xl "
            id="updateRoleAndPermissionModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                {console.log(setupdatemodal, "setupdatemodalllllllll")}
                <div className="modal-body">
                  <UpdateRoleAndPermission
                    showupdatamodal={setshowupdatamodal}
                    setcurrdata={setcurrdata}
                    setUpmodal={setupdatemodal}
                    currentUser={currentUser}
                    defaultValue={defaultValue}
                    lastUser={updateUser}
                    setlastUser={setupdateUser}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )} */}

      {/* NEW Update Role And Permission MODAL START */}
      {updateUser && (
        <div className="NEWMODALupdate">
          <div className="actmodalupdate">
            <div className="closemodalupdate">
              <button
                style={{
                  fontSize: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  color: "grey",
                  border: "none",
                }}
                onClick={() => setupdateUser(null)}
              >
                X
              </button>
            </div>
            <div className="modalContent">
              <UpdateRoleAndPermission
                setcurrdata={setcurrdata}
                //setUpmodal={setupdatemodal}
                // currentUser={currentUser}
                // defaultValue={defaultValue}
                lastUser={updateUser}
                setlastUser={setupdateUser}
              />
            </div>
          </div>
        </div>
      )}
      {/* NEWMODAL END */}

      {/*      
     {showupdatamodal && (  // Only render the modal if showupdatamodal is true
    <div 
        className="modal fade show" 
        style={{ display: 'block' }} // Show the modal with block display
        id="updateRoleAndPermissionModal"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
    >
        <div className="modal-dialog modal-xl">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Update Role and Permission</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setshowupdatamodal(false)} // Close modal on button click
                        aria-label="Close"
                    />
                </div>
                <div className="modal-body">
                    <UpdateRoleAndPermission 
                        setcurrdata={setcurrdata}
                        currentUser={currentUser}
                        defaultValue={defaultValue}
                    />
                </div>
            </div>
        </div>
    </div>
)} */}

      {show && (
        <Deleteuserconfirmation
          show={show}
          setShow={setshow}
          handleDelete={handleUserDelete}
          deleteId={deluser}
          sourceName="User"
        />
      )}

      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreatedUserTable;
