import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";



const Pagination = ({maxData,totalPages,totalElements,showingElements,pageNo,setPageNo,pageSize,setPagesize}) => {

    const [pageRangeStart, setPageRangeStart] = useState(0);
    const pagesToShow = 5;
   
useEffect(()=>{
 if(pageNo>=totalPages){
  setPageNo(totalPages-1)
 }
},[totalPages])

  const handlePagesize = (event) => {
    const number = totalElements;
    const size = event.target.value;
    if (pageNo > number / size) {
      setPageNo(
        number % size !== 0 ? Math.floor(number / size) : number / size - 1
      );
      setPageRangeStart(Math.floor(( number % size !== 0 ? Math.floor(number / size) : number / size - 1)/5)*5)
    }
    setPagesize(event.target.value);
  };

  const handleNextPageClick = () => {
    const newPageNo = pageNo + 1;
    if (newPageNo < totalPages) {
      setPageNo(newPageNo);
      if (newPageNo >= pageRangeStart + pagesToShow) {
        setPageRangeStart(pageRangeStart + pagesToShow);
      }
    }
  };
  const handlePreviousPageClick = () => {
    const newPageNo = pageNo - 1;
    if (newPageNo >= 0) {
      setPageNo(newPageNo);
      if (newPageNo < pageRangeStart) {
        setPageRangeStart(pageRangeStart - pagesToShow);
      }
    }
  };
  const handlePageClick = (index) => {
    setPageNo(index);
    if (index >= pageRangeStart + pagesToShow) {
      setPageRangeStart(pageRangeStart + pagesToShow);
    } else if (index < pageRangeStart) {
      setPageRangeStart(pageRangeStart - pagesToShow);
    }
  };


  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // paddingTop:"10px"
        borderTop:"1px solid #e6e5e3"
      }}
    >
      {totalElements>0&&
      <div
        className="page_size_control"
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "15px",
          fontWeight: "500",
          paddingBottom: "10px",
          justifySelf: "flex-start",
        }}
      >
        <span style={{ padding: "10px" }}>Showing:</span>
        <span style={{ marginRight: "8px" }}>
          {" "}
          {showingElements
            ?showingElements
            : 0}
        </span>
        out of
        <span className="page_size_control" style={{ padding: "10px" }}>
          {totalElements ? totalElements : 0}
        </span>
      </div>
}
      {totalElements > 12 && (
        <div className="dashboard_leads_pagination_div">
          <nav
            aria-label="..."
            style={{ display: "flex", justifySelf: "flex-end" ,alignItems:"center"}}
          >
            <div>
              <span className="page_size_control">Display</span>
              <input
                className="company_page_size"
                type="number"
                defaultValue={pageSize || 12}
                min={12}
                max={maxData}
                // step={4}
                onChange={handlePagesize}
              />
            </div>
            <ul className="pagination"  style={{ display: "flex", justifySelf: "flex-end" ,alignItems:"center",marginBottom:"0px"}}>
              {/* Previous page button */}
              <li className="page-item dashboard_leads_pagination_pageItem">
                <span
                  className="page-link"
                  // href="#!"
                  onClick={handlePreviousPageClick}
                >
                  <IoIosArrowBack />
                </span>
              </li>

              {/* Render page numbers */}
              {Array.from({ length: pagesToShow }, (_, index) => {
                const pageIndex = pageRangeStart + index;

                return (
                  pageIndex < totalPages && (
                    <li 
                      key={pageIndex}
                      className={`page-item ${
                        pageIndex === pageNo ? "active" : ""
                      } dashboard_leads_pagination_pageItem`}
                    >
                     
                      <span
                        className="page-link"
                        onClick={() => handlePageClick(pageIndex)}
                      >
                        {pageIndex + 1 < 10
                          ? `0${pageIndex + 1}`
                          : pageIndex + 1}
                      </span>
                    </li>
                  )
                );
              })}

              {/* Next page button */}
              <li className="page-item dashboard_leads_pagination_pageItem">
                <span
                  className="page-link"
                  onClick={handleNextPageClick}
                >
                  <IoIosArrowForward className="btn_IoIosArrowForward" />
                </span>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Pagination;
