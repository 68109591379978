  import { useDispatch,useSelector } from "react-redux";
  import { setField } from "../app/slices";






   const Chatheader = ()=>{
    const showchat = useSelector((state)=>state.chatslice.showchat)
    const dispatch = useDispatch();  
    function resetchat(){
      dispatch(setField({ field: 'showchat', value: false }));
      dispatch(setField({ field: 'newuser', value: false }));
      dispatch(setField({ field: 'name', value: false }));
      dispatch(setField({ field: 'email', value: false }));
      dispatch(setField({ field: 'mobile', value: false }));
    }

    return(
        <div style={{width:"100%",padding:"5px 10px",backgroundColor:"#5869ff",display:"flex",boxSizing:"border-box",border:"1px solid white",
        alignItems:"center",justifyContent:"space-between",color:"#FFFFFF",fontWeight:"700"}}>
           <div style={{}}>Let's have chat</div>
           <div><button style={{border:"none",color:'#5869ff',borderRadius:"2px"}}  onClick={resetchat}>X</button></div>
        </div>
    )
   }
   export default Chatheader;