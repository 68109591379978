import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Jmp from "./jmp";
import { useDispatch, useSelector } from "react-redux";
import { setField } from "../app/slices";
import { postuserdata } from "../controller/fetchApi";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, email, mobile, showchat, newuser } = useSelector(
    (state) => state.chatslice
  );
  const [newuserdata, setnewuserdata] = useState({
    name: "",
    email: "",
   // contact: "",
  });

  //update chatboat state
  const updatestate = (botMessage) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const fieldmessage = (fieldname,nextfeild)=>
    { 
    const botMessage = createChatBotMessage(`Please enter your ${fieldname}.`);
    updatestate(botMessage);
    dispatch(setField({ field: `${fieldname}`, value: false }));
    dispatch(setField({ field: `${nextfeild}`, value: true }));
  }

  const valid = () =>
     {
    const botMessage = createChatBotMessage("please select valid option.");
    updatestate(botMessage);
    const elm = createChatBotMessage(<Jmp />);
    updatestate(elm);
  };

  const handleHello = () => {
    const botMessage = createChatBotMessage("Please select your type.", {
      widget: "Realchat",
    });
    updatestate(botMessage);
  };

  //new user function
  const newuserF = async (message) => { 
    console.log(message)
    if (name) {
       fieldmessage("name","email" );
     
    } else if (email) { 
       setnewuserdata(prev=>({...prev,name:message}))
      fieldmessage("email","mobile");
      
    } else if (mobile) {
       setnewuserdata(prev=>({...prev,email:message}))
      fieldmessage("mobile","showchat");
    
    } else if (showchat) {
      const botMessage = createChatBotMessage(
        "Thank you,our team will shortly contact you."
      );
      updatestate(botMessage);
   
       //posting user data
       let data ={...newuserdata,'contact':message};
       console.log(data)
       postuserdata(data);
     
      dispatch(setField({ field: "newuser", value: false }));

      setTimeout(() => {
        dispatch(setField({ field: "showchat", value: false }));
      }, 5000);

    
      
    }
  };

  const existinguser = () => {
    const botMessage = createChatBotMessage("Please login.");

    updatestate(botMessage);

    setTimeout(() => {
      navigate("/login");
    }, 2000);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { handleHello, existinguser, newuserF, valid },
        });
      })}
    </div>
  );
};

export default ActionProvider;
