import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// Import CSS
import "../styles/roleAndPermission.css";
// Controllers
import {
  getRoles,
  getModules,
  getModulePermissions,
  updateRoleModulePermissions,
} from "../controller/fetchApi";
import { getSingleUserPermission } from "../controller/fetchApi";




const UpdateRoleAndPermission = ({
  setcurrdata,
  lastUser,
  setlastUser
}) => {
  // Get User Details from local storage
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));
  const tokenId = userIdTokenData?.data?.token;
    // Update Role And Permissions
  const [defaultValue, setDefaultValue] = useState();

  const [currentUser, setCurrentUser] = useState();

  const handleUpdateRoleAndPermission = async (user) => {

    const roleAndPermissions = await getSingleUserPermission(
      user?.id,
      tokenId
    );
    
    if(roleAndPermissions){
    setCurrentUser(user);
    setDefaultValue(roleAndPermissions);
    }
    // try { 
    //   // here getting user role and permission
    //   const roleAndPermissions = await getSingleUserPermission(
    //     user?.id,
    //     tokenId
    //   );
    //   console.log(roleAndPermissions,'roleAndPermiss ionsroleA ndPermissions roleAndPermissions')
    //   setCurrentUser(user);
    //   setDefaultValue(roleAndPermissions);
    // } catch (error) {
    //   console.log("Created User Update getModulePermissions :", error);
    // }
  };
   
  useEffect(()=>{
    handleUpdateRoleAndPermission(lastUser)
  },[lastUser])

  // Toast Code Start -----
  const [showToast, setShowToast] = useState({ success: false, message: "" ,optmessage:""});
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" ,optmessage:""});
    }, 3000);
  };
  if (showToast.message) {
    hideToast();
  }
  // Roles , Modules & Permissions import for UI purposes
  const [roles, setRoles] = useState();
  const [module, setModules] = useState([]);
  const [modulePermissions, setModulePermissions] = useState([]);
 
  // Initial Permissions Convert Desired Formate
  const transformDefaultValue = (data) => {
    const transformed = {};
    data?.roles[0]?.modules.forEach((module) => {
     
      transformed[module.id] = module.permissions;
      // transformed[module.id] = module.permissions.map(
      //   (permission, index) =>index + 1
      // );
    });
    return transformed;
  };
  const initialPermissions = transformDefaultValue(defaultValue);
  useEffect(() => {
    // set inital permission and roles
    setSelectedModulePermissions(initialPermissions);
    setSelectedRoleId(defaultValue?.roles[0]?.id);
  }, [defaultValue]);

  // handleRoleChecks
   const [selectedRolesId, setSelectedRoleId] = useState(0);

  const handleRoleChecks = (id) => {
    setSelectedRoleId(id);
  };


//const handleRoleChecks = (id) => {
  // Ensure selectedRolesId is an array before updating
  
  // if (Array.isArray(selectedRolesId)) {
  //   setSelectedRoleId([...selectedRolesId, id]);
  // } else {
    // If it's not an array, log an error or reset it to an array
    //console.error("selectedRolesId is not an array");
//     setSelectedRoleId([id]); // Reset to array containing the new id
//   }

// };
  // handlePermissionChecks
  const [selectedModulePermissions, setSelectedModulePermissions] = useState(
    {}
  );
 

  const handlePermissionChecks = (event, moduleId) => {
    let isSelected = event.target.checked;
    let value = parseInt(event.target.value);

    setSelectedModulePermissions((prevState) => {
      const newState = { ...prevState };
      if (isSelected) {
        if (!newState[moduleId]) {
          newState[moduleId] = [];
        }
        newState[moduleId].push(value);
      } else {
        if (newState[moduleId]) {
          newState[moduleId] = newState[moduleId].filter((id) => id !== value);
          if (newState[moduleId].length === 0) {
            delete newState[moduleId];
          }
        }
      }
      return newState;
    });
  };
  //disable form submit button
  const [disable, setdisable] = useState(false);

  // Submit Form
  const handleSubmit = async (event) => {
    setdisable(true);
    try {
      event.preventDefault();
      // Get Modules Id
      let modulesIdArray = [];
      for (const key in selectedModulePermissions) {
        modulesIdArray.push(Number(key));
      }
      // Make Module Permissions
      const modulePermissions = modulesIdArray.map((moduleId) => {
        return {
          moduleId: moduleId,
          permissionIds: selectedModulePermissions[moduleId],
        };
      });


      
      // Construct the desired output object
      const desiredFormat = {
        roleId: selectedRolesId,
        moduleIds: modulesIdArray,
        modulePermissions: modulePermissions,
      };

      // Log the desired format as a string
      const response = await updateRoleModulePermissions(
        desiredFormat,
        tokenId,
        currentUser?.id,
        setShowToast
      );
      if(response){
        setTimeout(() => {
          setcurrdata((prev) => !prev);
          setdisable(false);
          setlastUser(null)
        }, 3000);
       
      }
      //showupdatamodal(false)
      //setupdatemodal(true)
    } catch (error) {
      console.log("Error sending Role & Permissions", error);
    } 
    // finally {
    //   setcurrdata((prev) => !prev);
    //   setdisable(false);
    //   setlastUser(null)
    // }
  };

  // handleCheckAllPermissions Select All btn
  const handleCheckAllPermissions = (event, moduleId) => {
    let isSelected = event.target.checked;
    setSelectedModulePermissions((prevState) => {
      const newState = { ...prevState };
      if (isSelected) {
        const allPermissions = modulePermissions.map((perm) => perm.id);
        newState[moduleId] = allPermissions;
      } else {
        //delete newState[moduleId];
         newState[moduleId]=[]
      }
      return newState;
    });
  };
  useEffect(() => {
    (async () => {
      try {
        const response = await getRoles(tokenId);
        const moduleResponse = await getModules(tokenId);
        const modulePermissionsResponse = await getModulePermissions(tokenId);
      
        setRoles(response);
        setModules(moduleResponse);
        setModulePermissions(modulePermissionsResponse);
      } catch (error) {
        console.log(error,'error_______________')
      }
    })(); 
  }, [tokenId]);

  return (
    <div className="role_and_permission p-3">
      {/* Heading */}
      <div className="role_and_permission_heading dashboard_username_div ps-1" >
        <p className="dashboard_user_name">{`Username : ${currentUser?.firstName} ${currentUser?.lastName}`}</p>
        <p className="dashboard_user_name2">{`User Id : ${currentUser?.id}`}</p>
      </div>
      <form onSubmit={handleSubmit}>
        {/* Roles */}
        <div className="role_and_permission_roles_module">
          <p className="role_and_permission_role_module_text ps-1">Role</p>
          <div className="role_and_permission_role_options ps-4">
            {Array.isArray(roles) &&
              roles?.map((data, index) => ( 
                <div
                  className="form-check role_and_permission_role_module_checks col-xl-3"
                  key={index}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    id={data?.id}
                    name="role"
                    //name={data?.role + data?.id}
                    defaultChecked={
                      defaultValue?.roles?.length &&
                      defaultValue?.roles[0]?.role?.includes(data?.role)
                    }
                    value={data?.id}
                    onChange={(e) => {
                      if(e.target.checked){ handleRoleChecks(data?.id)}
                      else{ handleRoleChecks(null)}
                      //handleRoleChecks(data?.id);
                    }}
                  />
                
                  <label className="form-check-label" style={{fontSize:"19px",fontWeight:"450"}} htmlFor={data?.id}>
                    {data.value}
                  </label>

                  {/* added radio button instead checkbox */}

   
                  {/* <input
                    className="form-check-input"
                    type="radio"
                    name="role"
                    id={data?.id}
                    // name={data?.role + data?.id}
                    defaultChecked={
                      defaultValue?.roles?.length &&
                      defaultValue?.roles[0]?.role===currentUser.role
                    }
                    value={data?.id}
                    onChange={() => {
                      handleRoleChecks(data?.id);
                    }}
                  />
                 
                  <label className="form-check-label" htmlFor={data?.id}>
                    {data.role}
                  </label> */}
                </div>
              ))}
          </div>
        </div>
        {/* Horizontall line */}
        <div>
          <hr />
        </div>
        <div className="role_and_permission_module">
          <p className="role_and_permission_role_module_text ps-1">Module</p>
        </div>
        {/* Module Checkbox */}
        <div className="role_and_permission_modules ">
        
          {module?.map((data, index) => (
            <div
              className="col-xl-5 role_and_permission_details_main "
              key={index}
            >
      
              <details className="role_and_permission_faq_details">
                <summary className="role_and_permission_faq_summary">
                  {data?.module}
                </summary>
                <hr />
                {/* Master Checkbox */}
                <div className="form-check role_and_permission_role_module_checks">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultValue=""
                    id={`masterPermissionCheck-${data.id}`}
                    onChange={(e) => handleCheckAllPermissions(e, data.id)}
                    checked={
                      modulePermissions.every((perm) =>
                        selectedModulePermissions[data.id]?.includes(perm.id)
                      ) || false
                    }
                  />
                
                  <label
                    className="form-check-label"
                    htmlFor={`masterPermissionCheck-${data.id}`}
                  >
                    Select All
                  </label>
                </div>
                
                {/* Permissions */}
                <div className="role_and_permission_role_options mt-3">
                  <table className="role_and_permission_role_options">
                  {modulePermissions?.map((permissionData, index) => (
                    <div
                      className="form-check role_and_permission_role_module_checks col-xl-2"
                      key={index}
                    >
                    
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={permissionData?.permission + permissionData?.id}
                        checked={
                          selectedModulePermissions[data.id]?.includes(
                            permissionData?.id
                          )
                        }
                       
                        // defaultChecked={true}
                        value={permissionData?.id}
                        onChange={(e) => handlePermissionChecks(e, data?.id)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={
                          permissionData?.permission + permissionData?.id
                        }
                      >
                     {  permissionData?.permission}
                      </label>
                    </div>
                  ))}
                  </table>
                </div>
              </details>
            </div>
          ))}
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button
            // data-bs-toggle="modal"
            // data-bs-target="#updateRoleAndPermissionModal"
            className="create_lead_form_submitBtn"
            type="submit"
            disabled={disable}
          >
            Save
          </button>
        </div>
      </form>
      {/* Toast */}
      {showToast.message && (
        <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
          <div
            className="toast show create_lead_toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header create_lead_toast_header">
              <strong className="me-auto">Form Submitted Successfully</strong>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowToast({ success: false, message: "" ,optmessage:""})}
              />
            </div>
            <div className="toast-body">{showToast.message}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateRoleAndPermission;
