import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// css
import "../styles/signup.page.css";
import "../styles/login.page.css";
import "../styles/resetPassword.page.css";
// React Icons
import { FaEyeSlash } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";

// Formik
import { useFormik } from "formik";
import { resetPasswordFormSchema } from "../schema/FormValidation";

// Images
import resetImg from "../images/reset_img.png";

// Api Calling & Functions
// import { resetPasswordValidate } from "../controller/fetchApi";
import { resetPassword } from "../controller/fetchApi";
import { FcFeedback } from "react-icons/fc";

const ResetPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get("uid");
//  toogle password
const [password,setPassword] = useState(false);
const [confirm,setConfirm] = useState(false);
  // resetPasswordValidate(uid);
  // Form Handle & Validations
  const { values, errors, touched, handleBlur, handleChange, handleSubmit,isSubmitting } =
    useFormik({
      initialValues: {
        email: "",
        password: "",
        confirmPassword: "",
      },

      validationSchema: resetPasswordFormSchema,
      onSubmit: async (values, { resetForm,setSubmitting }) => {
       
          const setPasswordSuccessFully = await resetPassword(
            values,
            uid,
            setShowToast
          );
          if (setPasswordSuccessFully) {
            resetForm();
            setSubmitting(false);
          }
        
      },
    });
  // Toast
  const [showToast, setShowToast] = useState({ success: false, message: "" ,optmessaage:""});
  // Function to hide the toast after 3 seconds
  const hideToast = () => {
    setTimeout(() => {
      setShowToast({ success: false, message: "" ,optmessaage:""});
    }, 3000);
  };
  if (showToast) {
    hideToast();
  }

  return (
    <div className="container-fluid signup_body_div">
      <div className="row">
        {/* Left Main Div Start*/}
        <div className="col-xl-6 col-md-6 col-sm-12">
          <div className="container signup_left_container login_left_container">
            <div id="signup_left_mainDiv" className="login_left_mainDiv">
              <form onSubmit={handleSubmit}>
                <Link to="/forgotpassword">
                  <IoArrowBackCircleOutline className="login_back_button" />
                </Link>
                <p className=" signup_text_in_signup_left_mainDiv">
                  Reset Password
                </p>
                <div className="formGroup">
                  {/*Email*/}
                  <div className="signup_input_div">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label signup_div_input"
                      >
                        Email
                      </label>
                      <input 
                        type="email"
                        maxLength={40}
                        className="form-control signup_email_form_control reset_password_form_control"
                        id="exampleFormControlInput1"
                       placeholder="Enter email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.email && errors.email&&
                      <small className="loginPageErrorMessage">{errors.email}</small>
                      }
                         <FcFeedback className="signup_input_icons" />
                    </div>
                  </div>
                  {/*Password */}
                  <div className="signup_input_div">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label signup_div_input"
                      >
                        Password
                      </label>
                      <input
                        type={password?"text":"password"}
                        maxLength={15}
                        className="form-control signup_email_form_control reset_password_form_control"
                        id="exampleFormControlInput1"
                        placeholder="Enter password"          
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="signup_input_icons" onClick={()=>setPassword(prev=>!prev)}>{password?"🐵":"🙈"}</span>
                      {/* <FaEyeSlash className="signup_input_icons" onClick={()=>setPassword(prev=>!prev)}/> */}
                      {touched.password && errors.password&&
                      <small className="loginPageErrorMessage">{errors.password}</small>
                      }
                    </div>
                  </div>
                  {/* Confirm Password */}
                  <div className="signup_input_div">
                    <div className="mb-3 position-relative">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label signup_div_input"
                      >
                        Confirm Password
                      </label>
                      <input
                        type={confirm?"text":"password"}
                        maxLength={15}
                        className="form-control signup_email_form_control reset_password_form_control"
                        id="exampleFormControlInput1"
                        placeholder="Confirm password"                   
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="signup_input_icons" onClick={()=>setConfirm(prev=>!prev)}>{confirm?"🐵":"🙈"}</span>
                      {/* <FaEyeSlash className="signup_input_icons" onClick={()=>setConfirm(prev=>!prev)}/> */}
                      {touched.confirmPassword && errors.confirmPassword&&
                      <small className="loginPageErrorMessage">{errors.confirmPassword}</small>
                      }
                    </div>
                  </div>
                </div>
                {/* Submit Button */}
                <div className="signup_submit_div" style={{marginTop:"15px"}}>
                  <button type="submit" className=" signup_submit_button" disabled={isSubmitting}>
                    Reset
                  </button>
                </div>
              </form>
              {/* Toast */}
              {showToast.message && (
                <div className="toast-container position-fixed bottom-0 end-0 p-3 ">
                  <div
                    className="toast show create_lead_toast"
                    role="alert"
                    aria-live="assertive"
                    aria-atomic="true"
                  >
                    <div className="toast-header create_lead_toast_header">
                      <strong className="me-auto">
                        {showToast.message ? showToast?.optmessaage : "Error"}
                      </strong>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() =>
                          setShowToast({ success: false, message: "" ,optmessaage:""})
                        }
                      />
                    </div>
                    <div className="toast-body">{showToast.message}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* Left Main End */}
        {/* Right Main Div */}
        <div className="col-xl-6 col-md-6 col-sm-12 signup_right_bodyDiv">
          <div className="signup_right_mainDiv">
            <img src={resetImg} alt="resetPassword_img" className="img-fluid" />
          </div>
        </div>
      </div>
      {/* Right Main Div End*/}
    </div>
  );
};

export default ResetPassword;
