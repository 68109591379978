import Jmp from "./jmp";
import MessageParser from "./MessageParser"


const Realchat = (props)=>{
    const { actions } = props;

  console.log(props)

    return(
       <MessageParser  actions={actions}>
           <Jmp {...props}/>
       </MessageParser>     
    )
}
export default Realchat;