import React, { useEffect, useState, useCallback, useContext } from "react";
// React Icons
import { MdAdd } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { BsPencil, BsTrash } from "react-icons/bs";
import LeadsRightSectionTable from "./shared/LeadsRightSectionTable";
import { MdVerified } from "react-icons/md";
import { MdOutlineUploadFile } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import Deleteuserconfirmation from "./deleteuserconfirmation";
import filter_icon from "../images/filter_icon.png";
// React Router Dom
import { Link } from "react-router-dom";
import permissionContext from "../pages/PermissionsContext";
// Controllers Api Methods
import {
  getAllLeadByFilter,
  deleteLeads,
  getSingleLead,
  verifyLeads,
  downloadLeads,
  uploadLeads,
} from "../controller/fetchApi";
// Components
import UpdateLead from "../pages/dashboardPages/UpdateLead";
import AssignLeads from "./AssignLeads";
// Import Toast
import Toast from "./Toast";
import Loader from "../pages/Loader";
import FilterSidebar from "./LeadsLeftSection";
import Pagination from "./pagination";

const LeadsRightSection = ({
  setFilter,
  leadCostumerId,
  setLeadCostumerId,
  filterData,
}) => {
  const [assignlead, setAssignLead] = useState(false);
  const [updatelead, setupdatelead] = useState(false);
  const [updateleadData, setupdateleadId] = useState(null);
  const [updatedLead, setupdatedLead] = useState(false);

  // Get lead permission From app.js
  const { leadsPermission } = useContext(permissionContext);

  // Start Toast Code-------
  const [showToast, setShowToast] = useState({
    success: false,
    message: "",
    optmessage: "",
  });

  const closeToast = () => {
    setShowToast({ success: false, message: "", optmessage: "" });
  };
  if (showToast.message) {
    setTimeout(closeToast, 3000);
  }

  // Set PageNo to getAllLead Api
  const [getAllLeadData, setAllLeadsData] = useState([]);
  // const [pageRangeStart, setPageRangeStart] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPagesize] = useState(12);
  const totalPages = getAllLeadData?.totalPages || 1;
  const showingElements= getAllLeadData?.numberOfElements;
  const totalElements=getAllLeadData?.totalElements;

  // console.log(showingElements,'showingElements showingElements')
  // const pagesToShow = 5;

  // const handlePagesize = (event) => {
  //   const number = getAllLeadData?.totalElements;
  //   const size = event.target.value;
  //   if (pageNo > number / size) {
  //     setPageNo(
  //       number % size !== 0 ? Math.floor(number / size) : number / size - 1
  //     );
  //     setPageRangeStart(
  //       Math.floor(
  //         (number % size !== 0
  //           ? Math.floor(number / size)
  //           : number / size - 1) / 5
  //       ) * 5
  //     );
  //   }
  //   setPagesize(event.target.value);
  // };

  const userLeadIdData = JSON.parse(localStorage.getItem("leadId"));
  const leadId = userLeadIdData;

  const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const userrole = userIdTokenData?.data?.roleAndPermissions?.roles[0]?.role;
  const tokenId = userIdTokenData?.data?.token;

  const [loading, setloading] = useState(true);

  const getLeadsData = useCallback(async () => {
    const filter = {
      page: pageNo,
      size: pageSize,
      ...filterData,
    };
    try {
      const res = await getAllLeadByFilter(filter, tokenId, setShowToast);
      setAllLeadsData(res);
    } catch (error) {
      setShowToast({
        success: true,
        message: "Something went wrong.",
        optmessage: "Finding lead...",
      });
      console.log(error);
    } finally {
      setloading(false);
    }
  }, [filterData, tokenId, pageNo, setAllLeadsData, pageSize, updatedLead]);
  //user id for deletion and confirmation for deletion
  const [dellead, setdellead] = useState({
    leadId: null,
    setLeadId: null,
  });
  const [show, setshow] = useState(false);

  // Delete Api Start---------------
  const handleDeleteLead = async (pin) => {
    if (dellead.leadId.length) {
      const response = await deleteLeads(
        dellead.leadId,
        dellead.setLeadId,
        setShowToast,
        tokenId,
        pin
      );
      if (response) {
        setTimeout(() => {
          getLeadsData();
        }, 1000);
        setshow(false);
      }
    } else {
      setShowToast({
        success: true,
        message: "Please select lead to delete.",
        optmessage: "Deleting leads.",
      });
    }
  };

  // Update Btn Click Action Start--------
  const [defaultValue, setDefaultValue] = useState([]);
  const handleUpdateLead = async () => {
    try {
      const singleLeadResult = await getSingleLead(leadId, tokenId);
      if (singleLeadResult) {
        getLeadsData();
        setDefaultValue(singleLeadResult);
      } else {
        setDefaultValue([]);
      }
    } catch (error) {
      console.log("LeadRightSection SingleLead :", error);
      setDefaultValue([]);
    }
  };

  //  Verifyleads Function Start -----

  const handleVerifyLeads = async (leadId, setLeadCostumerId) => {
    if (leadId.length) {
      try {
        await verifyLeads(leadId, setShowToast, tokenId);

        if (verifyLeads) {
          getLeadsData();
        }
      } catch (error) {
        console.log("LeadRightSection SingleLead :", error);
      } finally {
        setLeadCostumerId([]);
      }
    } else {
      setShowToast({
        success: true,
        message: "Please select leads to  Verify.",
        optmessage: " Verifing leads...",
      });
    }
  };

  // Handle Download Api ------
  const handleDownloadLeads = async () => {
    try {
      await downloadLeads(setShowToast, tokenId);
    } catch (error) {
      console.log("LeadRightSection Failed Downloaded:", error);
    }
  };

  // Handle Upload File start ----
  const [selectedFile, setSelectedFile] = useState(null);
  const [Uploading,setDisuploading] = useState(false)
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handleUploadLeads = async () => {
    if (selectedFile) {
      setDisuploading(true)
      try {
        await uploadLeads(selectedFile, setShowToast, tokenId);
        if (uploadLeads) {
          getLeadsData();
          setDisuploading(false);
        }
      } catch (error) {
        console.log("LeadRightSection Failed Uploading:", error);
      }
    }
  };
  // Pagination Function ------

  // const handleNextPageClick = () => {
  //   const newPageNo = pageNo + 1;
  //   if (newPageNo < totalPages) {
  //     setPageNo(newPageNo);
  //     if (newPageNo >= pageRangeStart + pagesToShow) {
  //       setPageRangeStart(pageRangeStart + pagesToShow);
  //     }
  //   }
  // };
  // const handlePreviousPageClick = () => {
  //   const newPageNo = pageNo - 1;
  //   if (newPageNo >= 0) {
  //     setPageNo(newPageNo);
  //     if (newPageNo < pageRangeStart) {
  //       setPageRangeStart(pageRangeStart - pagesToShow);
  //     }
  //   }
  // };
  // const handlePageClick = (index) => {
  //   setPageNo(index);
  //   if (index >= pageRangeStart + pagesToShow) {
  //     setPageRangeStart(pageRangeStart + pagesToShow);
  //   } else if (index < pageRangeStart) {
  //     setPageRangeStart(pageRangeStart - pagesToShow);
  //   }
  // };

  useEffect(() => {
    getLeadsData();
  }, [getLeadsData]);

  return (
    <div className="conatiner-fluid dashboard_rightLeads_main_containers">
     
        <div className="dashboard_content_wrapper">
          {/* Btn Div */}
          <div className="dashboard_leads_btn_mainDiv">
            <div
              className="filter-sidebar-header"
              style={{ marginTop: "14px", cursor: "pointer" }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setFilter((prev) => !prev);
              }}
            >
              <div>
                <h3 style={{ color: "white", textAlign: "center" }}>
                  <span style={{ margin: "8px 0px 0px 3px", fontSize: "18px" }}>
                    Filters:
                  </span>
                  <img
                    width="30px"
                    src={filter_icon}
                    style={{ margin: "8px 0px 0px 3px" }}
                  />
                </h3>
              </div>
            </div>
            <div className="dashboard_leads_btns_div">
              {/* <FilterSidebar setFilterData={setFilterData} /> */}
              <div className="dashboard_leads_verify_btn_div">
                <button
                  className="leads_verify_btn"
                  onClick={() =>
                    handleVerifyLeads(leadCostumerId, setLeadCostumerId)
                  }
                >
                  <MdVerified className="leads_verify_btn_icon" />
                  Verify Leads
                </button>
              </div>
              <div
                className="dashboard_leads_action_btn_div dashboard_leads_assign_div"
                onClick={() => {
                  leadCostumerId.length
                    ? setAssignLead(true)
                    : setShowToast({
                        success: true,
                        message: "Please select lead.",
                        optmessage: "Assigning lead...",
                      });
                }}
              >
                <button className="dashboard_section1_table_edit_button dashboard_leads_assign_btn">
                  Assign
                </button>
              </div>
              <div className="dashboard_leads_action_btn_div">
                <button
                  className="dashboard_section1_table_edit_button dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Actions
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="editDeleteDropdown"
                  >
                    {leadsPermission?.includes("Update") ? (
                      <li
                      // data-bs-toggle="modal"
                      // data-bs-target="#updateLeadModal"
                      >
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            if (updateleadData) {
                              setupdatelead(true);
                            } else {
                              setShowToast({
                                success: true,
                                message: "Please select lead.",
                                optmessage: "Edit lead...",
                              });
                              // alert("Please select a lead.");
                            }
                          }}
                        >
                          <BsPencil className="dashboard_section1_table_editBtn" />
                          Edit
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Delete Btn */}
                    {leadsPermission?.includes("Delete") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => {
                            //handleDeleteLead(leadCostumerId,setLeadCostumerId)
                            if (leadCostumerId?.length) {
                              setshow(true);
                              setdellead({
                                leadId: leadCostumerId,
                                setLeadId: setLeadCostumerId,
                              });
                            } else {
                              setShowToast({
                                success: true,
                                message: "Select lead to delete.",
                                optmessage: "Deleting Lead.",
                              });
                            }
                          }}
                        >
                          <BsTrash className="dashboard_section1_table_deleteBtn" />
                          Delete
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Upload Btn */}
                    {leadsPermission?.includes("Upload") ? (
                      <li
                        data-bs-toggle="modal"
                        data-bs-target="#fileUploadModal"
                      >
                        <span className="dropdown-item">
                          <MdOutlineUploadFile className="dashboard_section1_table_deleteBtn" />
                          Upload Leads
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {/* Download Btn */}
                    {leadsPermission?.includes("Download") ? (
                      <li>
                        <span
                          className="dropdown-item"
                          onClick={() => handleDownloadLeads()}
                        >
                          <TbFileDownload className="dashboard_section1_table_deleteBtn" />
                          Download Leads
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </button>
              </div>
              {leadsPermission?.includes("Create") && (
                <div className="dashboard_leads_create_btn_div">
                  {/* old button */}
                  <button className="btn-shiny2">
                    <Link
                      className="dashboard_leads_create_link"
                      to="/create-lead"
                    >
                      <span>
                        <MdAdd />
                      </span>
                      Create Leads
                    </Link>
                  </button>
                </div>
              )} 
            </div>
          </div>
          {/* Table Div */}
          <div className="dashboard_leads_table_div" >
            <LeadsRightSectionTable
            loading={loading}
             pageNo={pageNo}
             pageSize={pageSize}
              setDefaultValue={setDefaultValue}
              setupdateleadId={setupdateleadId}
              updateleadData={updateleadData}
              tblHead={{srHead:"Sr No",
                zeroonehead: "Created At",
                zerohead: ["PROJECTMANAGER", "SALESEXECUTIVE"]?.includes(
                  userrole
                )
                  ? "Assigned By"
                  : "Assigned To",
                firstHead: "Name",
                secondHead: "Mobile No",
                contactHead: "Whatsapp",
                callHead: "Call",
                thirdHead: "Lead Source",
                fourthHead: "Lead Status",
                fifthHead: "View",
                sixthHead: "Contact Action",
                seventhHead: "Lead Id",
              }}
              redirectLink="/lead-details"
              getAllLeadData={getAllLeadData}
              tableName="leads Table"
            />
          </div>

          {/*new Pagination Div */}
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              className="page_size_control"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
                fontWeight: "500",
                paddingBottom: "10px",
                justifySelf: "flex-start",
              }}
            >
              <span style={{ padding: "10px" }}>Showing:</span>
              <span style={{ marginRight: "8px" }}>
                {" "}
                {getAllLeadData?.numberOfElements
                  ? getAllLeadData?.numberOfElements
                  : 0}
              </span>
              out of
              <span className="page_size_control" style={{ padding: "10px" }}>
                {getAllLeadData?.totalElements
                  ? getAllLeadData?.totalElements
                  : 0}
              </span>
            </div>
            {getAllLeadData?.totalElements > 12 && (
              <div className="dashboard_leads_pagination_div">
                <nav
                  aria-label="..."
                  style={{ display: "flex", justifySelf: "flex-end" }}
                >
                  <div>
                    <span className="page_size_control">Display</span>
                    <input
                      className="company_page_size"
                      type="number"
                      defaultValue={pageSize || 12}
                      min={12}
                      step={4}
                      onChange={handlePagesize}
                    />
                  </div>
                  <ul className="pagination">
                    
                    <li className="page-item dashboard_leads_pagination_pageItem">
                      <span
                        className="page-link"
                       
                        onClick={handlePreviousPageClick}
                      >
                        <IoIosArrowBack />
                      </span>
                    </li>

                  
                    {Array.from({ length: pagesToShow }, (_, index) => {
                      const pageIndex = pageRangeStart + index;

                      return (
                        pageIndex < totalPages && (
                          <li
                            key={pageIndex}
                            className={`page-item ${
                              pageIndex === pageNo ? "active" : ""
                            } dashboard_leads_pagination_pageItem`}
                          >
                            <a
                              className="page-link"
                              href="#!"
                              onClick={() => handlePageClick(pageIndex)}
                            >
                              {pageIndex + 1 < 10
                                ? `0${pageIndex + 1}`
                                : pageIndex + 1}
                            </a>
                          </li>
                        )
                      );
                    })}

                   
                    <li className="page-item dashboard_leads_pagination_pageItem">
                      <span
                        className="page-link"
                       
                        onClick={handleNextPageClick}
                      >
                        <IoIosArrowForward className="btn_IoIosArrowForward" />
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div> */}
          <Pagination 
            maxData={totalElements}
            totalPages={totalPages} 
            totalElements={totalElements}
            showingElements={showingElements}
            pageNo={pageNo}
            setPageNo={setPageNo}
            pageSize={pageSize}
            setPagesize={setPagesize}
          />
          {/* old pagination div */}
          {/* <div className="dashboard_leads_pagination_div">
            <div
              className="page_size_control"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "15px",
                fontWeight: "500",
                paddingBottom: "10px",
                justifySelf: "flex-start",
              }}
            >
              <span style={{ padding: "10px" }}>Showing:</span>
              {getAllLeadData?.numberOfElements
                ? getAllLeadData?.numberOfElements
                : 0}{" "}
              out of
              <span className="page_size_control" style={{ padding: "10px" }}>
                {getAllLeadData?.totalElements
                  ? getAllLeadData?.totalElements
                  : 0}
              </span>
            </div>
            <nav
              aria-label="..."
              style={{ display: "flex", justifySelf: "flex-end" }}
            >
              <div>
                <span className="page_size_control">Display</span>
                <input
                  className="company_page_size"
                  type="number"
                  defaultValue={12}
                  min={1}
                  step={4}
                  onChange={handlePagesize}
                />
              </div>
              <ul className="pagination">
              
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handlePreviousPageClick}
                  >
                    <IoIosArrowBack />
                  </a>
                </li>

               
                {Array.from({ length: pagesToShow }, (_, index) => {
                  const pageIndex = pageRangeStart + index;
                  return (
                    pageIndex < totalPages && (
                      <li
                        key={pageIndex}
                        className={`page-item ${
                          pageIndex === pageNo ? "active" : ""
                        } dashboard_leads_pagination_pageItem`}
                      >
                        <a
                          className="page-link"
                          href="#!"
                          onClick={() => handlePageClick(pageIndex)}
                        >
                          {pageIndex + 1 < 10
                            ? `0${pageIndex + 1}`
                            : pageIndex + 1}
                        </a>
                      </li>
                    )
                  );
                })}

              
                <li className="page-item dashboard_leads_pagination_pageItem">
                  <a
                    className="page-link"
                    href="#!"
                    onClick={handleNextPageClick}
                  >
                    <IoIosArrowForward className="btn_IoIosArrowForward" />
                  </a>
                </li>
              </ul>
            </nav>
          </div> */}
          {/*Update Lead  Modal */}
          {/* <>
          <div
            className="modal fade modal-xl"
            id="updateLeadModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header w-100">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <UpdateLead
                    leadCostumerId={leadCostumerId}
                    defaultValue={defaultValue}
                    getLeadsData={getLeadsData}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </> */}
          {/* new update lead Modal */}
          {updatelead && updateleadData && (
            <div className="NEWMODALupdate">
              <div className="actmodalupdate">
                <div className="closemodalupdate">
                  <button
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                      border: "none",
                    }}
                    onClick={() => {
                      setupdatelead(false);
                    }}
                  >
                    X
                  </button>
                </div>
                <div className="modalContent">
                  <UpdateLead
                    setupdatelead={setupdatelead}
                    setupdatedLead={setupdatedLead}
                    leadData={updateleadData}
                    leadCostumerId={leadCostumerId}
                    defaultValue={defaultValue}
                    getLeadsData={getLeadsData}
                  />
                </div>
              </div>
            </div>
          )}
          {/* File Upload Modal */}
          <>
            <div
              className="modal fade"
              id="fileUploadModal"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header w-100">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <form>
                      <label
                        htmlFor="exampleFormControlFile1"
                        className="form-label"
                      >
                        Choose file
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="exampleFormControlFile1"
                        onChange={handleFileChange}
                      />
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onClick={handleUploadLeads}
                      // disabled={Uploading}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/*Assign Leads Modal */}
          {/* {
            assignlead&& <>
            <div
              className="modal fade modal-xl "
              id="assignLeadsModal"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header w-100">
                    <span className="fw-bold">Users</span>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <AssignLeads getLeadsData={getLeadsData} />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          } */}

          {assignlead && (
            <div className="NEWMODALupdate">
              <div className="actmodalupdate">
                <div className="closemodalupdate">
                  <button
                    style={{
                      fontSize: "30px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      color: "grey",
                      border: "none",
                    }}
                    onClick={() => setAssignLead(false)}
                  >
                    X
                  </button>
                </div>
                <div className="modalContent">
                  <AssignLeads
                    getLeadsData={getLeadsData}
                    setAssignLead={setAssignLead}
                    setLeadCostumerId={setLeadCostumerId}
                  />
                </div>
              </div>
            </div>
          )}

          {/* <>
            <div
              className="modal fade modal-xl "
              id="assignLeadsModal"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header w-100">
                    <span className="fw-bold">Users</span>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </div>
                  <div className="modal-body">
                    <AssignLeads getLeadsData={getLeadsData} />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </> */}
          <Deleteuserconfirmation
            show={show}
            setShow={setshow}
            handleDelete={handleDeleteLead}
            sourceName="Lead"
          />
          <Toast showToast={showToast} setShowToast={setShowToast} />
        </div>
     
    </div>
  );
};

export default LeadsRightSection;
