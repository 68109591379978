import React, { useEffect, useState } from 'react';
import { useSelector,useDispatch } from 'react-redux';

const MessageParser = ({ children, actions }) => {
   
   const user=useSelector((state)=>state.chatslice.newuser)
   console.log(user)

 //here parse function will get user input and  you can write your condition and based on that execute certain action provided from actionprovider component. 
    const parse = (message) => { 
      console.log(user)
    
       if(user){
        actions.newuserF(message);
       }
       else if(message.includes('existing_user9763453648934')){
        actions.existinguser()
      }
      else{
        actions.valid(message);
       }
      };

   
      useEffect(() => {
        console.log("User state has changed:", user);
  
      }, [user]);
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse,
         actions
        });
      })}
    </div>
  );
};

export default MessageParser;