import React, { useState } from "react";
import { useFormik } from "formik";
// React Icon
import { MdAdminPanelSettings } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BsCurrencyRupee } from "react-icons/bs";
import { BsBuildingsFill } from "react-icons/bs";
import { FaTreeCity } from "react-icons/fa6";
import { FaLandmarkFlag } from "react-icons/fa6";
import { registerSchema } from "../schema/FormValidation";
// Controller Api Methods
import { createLead } from "../controller/fetchApi";
// Import Toast
import Toast from "./Toast";
import { useNavigate } from "react-router-dom";
const CreateLeadForm = ({ leadStatus, leadServices, leadSource }) => {
  const navigate = useNavigate()
  // Get TokenId And User Id
  const userIdTokenData = JSON.parse(localStorage.getItem("user"));

  const uid = userIdTokenData?.data?.userId;
  const tokenId = userIdTokenData?.data?.token;
  // Toast
  const [showToast, setShowToast] = useState({ success: false, message: "" });

  // Form Handle & Validations
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    isSubmitting,
    resetForm,
    setSubmitting
  } = useFormik({
    initialValues: {
      leadOwner:userIdTokenData?.data?.fullName,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      mobileAlt: "",
      leadSource: "",
      leadStatus: "",
      annualRevenue: "",
      companyName: "",
      companyEmail: "",
      companyContact: "",
      secondaryContact: "",
      city: "",
      district: "",
      state: "",
      country: "",
      description: "",
      phone: "",
      emailAlt: "",
      subject: "",
      pinCode: "",
      address: "",
      productName: "",
      priority:"",
      leadType:''
    },

    validationSchema: registerSchema,
    onSubmit: async (values, { resetForm,setSubmitting, setFieldError }) => {
      if(!values.email||values.email&&(values.email!==values.emailAlt)){
      try {
        const registerSuccessFully = await createLead(
          values,
          uid,
          setShowToast,
          tokenId
        );
 
        
        if (registerSuccessFully?.data?.status===200) {
          setTimeout(() => {
            navigate("/leads")
          }, 4000);
         
          resetForm();
        }
      } catch (error) {
        console.log("Found Error", error);
      }finally{
        setSubmitting(false)
      }}
      else{
        setFieldError("emailAlt","Please enter different email")
      }
    },
  });

  // const createlead = async (event)=>{event.preventDefault();
  
  //   try {
  //     const registerSuccessFully = await createLead(
  //       values,
  //       uid,
  //       setShowToast,
  //       tokenId
  //     );

  //     if (registerSuccessFully) {
  //       resetForm();
  //     }
  //   } catch (error) {
  //     console.log("Found Error", error);
  //   }finally{
  //     setSubmitting(false)
  //   }
  // }
  // Function to handle input focus
  const handleFocus = (e) => {
    const { name } = e.target;
    setFieldTouched(name, true);
  };

  return (
    <div className="create_lead_form_main_div">
      <form onSubmit={handleSubmit}>
        {/* Create Lead Heading */}
        <div className="create-lead-heading">
          <div>
            <p className="create_lead_section2_company_info">Lead Details</p>
          </div>
          {/* <div className="create-lead-heading-right-part">
            <p className="create-lead-heading-right-para">
              Lead Name :{" "}
              <span className="create-lead-heading-span">Pankaj Swami Vaishnav</span>
            </p>
            <p className="create-lead-heading-right-para">
              Lead Id : <span className="create-lead-heading-span">2024</span>
            </p>
          </div> */}
        </div>
        {/* User Information */}
        <div className="row">
      
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="leadOwner" style={{marginBottom:"5px"}}>
              Lead Owner <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="leadOwner"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.leadOwner}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadOwner"
              placeholder="Enter Lead Owner Name"
            />
            {touched.leadOwner && errors.leadOwner && (
              <small className="errorMessage">{errors.leadOwner}</small>
            )}
            <FaUserTie className="create_lead_input_icon" />
          </div>
          
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="firstName" style={{marginBottom:"5px"}}>
              First Name<span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="firstName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.firstName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="firstName"
              placeholder="Enter first name"
            />
            {touched.firstName && errors.firstName && (
              <small className="errorMessage">{errors.firstName}</small>
            )}
            <FaUserTie className="create_lead_input_icon" />
          </div>
         
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="lastName" style={{marginBottom:"5px"}}>
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.lastName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="lastName"
              placeholder="Enter last name"
            />
            {touched.lastName && errors.lastName && (
              <small className="errorMessage">{errors.lastName}</small>
            )}
            <FaUserTie className="create_lead_input_icon" />
          </div>
         
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="email" style={{marginBottom:"5px"}}>
              Email
            </label>
            <input
              type="email"
              id="email"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.email}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="email"
              placeholder="Enter your email "
            />
            {touched.email && errors.email && (
              <small className="errorMessage">{errors.email}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
        
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="emailAlt" style={{marginBottom:"5px"}}>
              Alternative Email  
            </label>
            <input
              type="email"
              id="emailAlt"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.emailAlt}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="emailAlt"
              placeholder="Enter Alternative email "
            />
            {touched.emailAlt && errors.emailAlt && (
              <small className="errorMessage">{errors.emailAlt}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="mobileNumber" style={{marginBottom:"5px"}}>
              Mobile Number <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="mobileNumber"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobile}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobile"
              placeholder="Enter Mobile Number"
            />
            {touched.mobile && errors.mobile && (
              <small className="errorMessage">{errors.mobile}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
         
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="secondaryMobileNumber" style={{marginBottom:"5px"}}>
              Secondary Mobile Number
            </label>
            
            <input
              type="text"
              id="secondaryMobileNumber"
              maxLength={10}
              className="form-control create_lead_form_input"
              value={values.mobileAlt}
              onChange={handleChange}
              minLength="10"
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="mobileAlt"
              placeholder="Other Mobile Number"
            />
            {touched.mobileAlt && errors.mobileAlt && (
              <small className="errorMessage">
                {errors.mobileAlt}
              </small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
         
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="phone" style={{marginBottom:"5px"}}>
            Phone Number 
            </label>
            <input
              type="text"
              id="phone"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.phone}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="phone"
              placeholder="Enter Mobile Number"
            />
            {touched.phone && errors.phone && (
              <small className="errorMessage">{errors.phone}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
        
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="phoneAlt">
            Alternative Phone Number <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="phoneAlt"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.phoneAlt}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="phoneAlt"
              placeholder="Enter Mobile Number"
            />
            {touched.phoneAlt && errors.phoneAlt && (
              <small className="errorMessage">{errors.phoneAlt}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="leadSource" style={{marginBottom:"5px"}}>
              Lead Source 
            </label>
            <select
              id="leadSource"
              className="form-control"
              value={values.leadSource}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadSource"
              style={{color:"#666565"}}
            >
              <option value="" hidden>
               
                Select Lead Source
              </option>
              {leadSource && leadSource?.length > 0
                ? leadSource?.map((source) => (
                    <option key={source?.id} value={source?.value}>
                      {source?.leadSource}
                    </option>
                  ))
                : ""}
            </select>
            {touched.leadSource && errors.leadSource && (
              <small className="errorMessage">{errors.leadSource}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
        
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="leadStatus" style={{marginBottom:"5px"}}>
              Lead Status
            </label>
            <select
              id="leadStatus"
              className="form-control"
              value={values.leadStatus}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadStatus"
              style={{color:"#666565"}}
            >
              <option value="" hidden>
               
                Select Lead Status
              </option>

           
              {leadStatus && leadStatus?.length > 0
                ? leadStatus?.map((lead) => (
                    <option key={lead.id} value={lead.value}>
                      {lead.leadSource}
                    </option>
                  ))
                : ""}
             
            </select>
            {touched.leadStatus && errors.leadStatus && (
              <small className="errorMessage">{errors.leadStatus}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
       
          {/* <div className="form-group createLeadInput col-xl-4 costum-select">
            <label htmlFor="leadStatus">
              Lead Service <span className="required_sign">*</span>
            </label>

            <select
              id="leadService"
              className="form-control"
              value={values.leadService}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadService"
              style={{color:"#666565"}}
            >
              <option value="" hidden>
                Select Lead Service
              </option>

              {leadServices && leadServices?.length > 0
                ? leadServices?.map((services) => (
                    <option key={services.id} value={services.leadSoruce}>
                      {services.leadSoruce}
                    </option>
                  ))
                : ""}
            </select>
            {touched.leadService && errors.leadService && (
              <small className="errorMessage">{errors.leadService}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div> */}
    
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="subject" style={{marginBottom:"5px"}}>
           Subject 
            </label>
            <input
              type="text"
              id="subject"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.subject}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="subject"
              placeholder="Enter subject"
            />
            {touched.subject && errors.subject && (
              <small className="errorMessage">{errors.subject}</small>
            )}
          
          </div>
      
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="queryMcatName">
            queryMcat Name <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="queryMcatName"
              className="form-control create_lead_form_input"
              value={values.queryMcatName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="queryMcatName"
              placeholder="Enter queryMcatName"
            />
            {touched.queryMcatName && errors.queryMcatName && (
              <small className="errorMessage">{errors.queryMcatName}</small>
            )}
          </div> */}
      
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="productName" style={{marginBottom:"5px"}}>
            Product Name 
            </label>
            <input
              type="text"
              id="productName"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.productName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="productName"
              placeholder="Enter product name"
            />
            {touched.productName && errors.productName && (
              <small className="errorMessage">{errors.productName}</small>
            )}
            
          </div>
  
         {/*priority  */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="priority" style={{marginBottom:"5px"}}>
            Priority 
            </label>
            <select
              id="priority"
              className="form-control"
              value={values.priority}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="priority"
              style={{color:"#666565"}}
            >
              <option value="" hidden>Priority</option>
             <option value="high">High</option>
             <option value="normal">Normal</option>
             <option value="medium">Medium</option>
            </select>
            {touched.priority && errors.priority && (
              <small className="errorMessage">{errors.priority}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
          {/* lead type */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="leadType" style={{marginBottom:"5px"}}>
            Lead type <span className="required_sign">*</span>
            </label>
            <select
              id="leadType"
              className="form-control"
              value={values.leadType}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="leadType"
              style={{color:"#666565"}}
            >
              <option value="" hidden>Lead type</option>
             <option value="unverified">Unverified</option>
             <option value="verified">Verified</option>
            </select>
            {touched.leadType && errors.leadType && (
              <small className="errorMessage">{errors.leadType}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="callDuration">
            Call Duration <span className="required_sign">*</span>
            </label>
            <input
              type="text"
              id="callDuration"
              className="form-control create_lead_form_input"
              value={values.callDuration}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="callDuration"
              placeholder="Enter callDuration"
            />
            {touched.callDuration && errors.callDuration && (
              <small className="errorMessage">{errors.callDuration}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div> */}
        </div>
 
        <div className="row">
          <p className="create_lead_section2_company_info">Company Details</p>
          {/* <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="annualRevenue" style={{marginBottom:"5px"}}>Annual Revenue</label>
            <input
              type="number"
              id="annualRevenue"
              maxLength={12}
              className="form-control create_lead_form_input"
              value={values.annualRevenue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="annualRevenue"
              placeholder="Enter Annual Revenue"
            />
            {touched.annualRevenue && errors.annualRevenue && (
              <small className="errorMessage">{errors.annualRevenue}</small>
            )}
            <BsCurrencyRupee className="create_lead_input_icon" />
          </div> */}
          {/* annual revenue */}
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="annualRevenue" style={{marginBottom:"5px"}}>
            Annual Revenue
            </label>
            <select
              id="priority"
              className="form-control"
              value={values.annualRevenue}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="annualRevenue"
              style={{color:"#666565"}}
            >
              <option value="" hidden> Annual Revenue</option>
             <option value="1 lakh - 10 lakh">1 lakh to 10 lakh</option>
             <option value="10 lakh to 50 lakh">10 lakh to 50 lakh</option>
             <option value="50 lakh to 1 cr">50 lakh to 1 cr</option>
             <option value="1 cr to 5 cr">1 cr to 5 cr</option>
            </select>
            {touched.priority && errors.priority && (
              <small className="errorMessage">{errors.priority}</small>
            )}
            <MdKeyboardArrowDown className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyName" style={{marginBottom:"5px"}}>Company Name</label>
            <input
              type="text"
              id="companyName"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.companyName}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyName"
              placeholder="Enter company name"
            />
            {touched.companyName && errors.companyName && (
              <small className="errorMessage">{errors.companyName}</small>
            )}
            <BsBuildingsFill className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyEmail" style={{marginBottom:"5px"}}>Company Email</label>
            <input
              type="email"
              id="companyEmail"
              maxLength={50}
              className="form-control create_lead_form_input"
              value={values.companyEmail}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyEmail"
              placeholder="Enter comapny email"
            />
            {touched.companyEmail && errors.companyEmail && (
              <small className="errorMessage">{errors.companyEmail}</small>
            )}
            <MdEmail className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="companyContact" style={{marginBottom:"5px"}}>Company Contact</label>
            <input
              type="text"
              id="companyContact"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.companyContact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="companyContact"
              placeholder="Enter company contact"
            />
            {touched.companyContact && errors.companyContact && (
              <small className="errorMessage">{errors.companyContact}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="secondaryContact" style={{marginBottom:"5px"}}>Secondary Contact </label>
            <input
              type="text"
              id="secondaryContact"
              maxLength={15}
              className="form-control create_lead_form_input"
              value={values.secondaryContact}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="secondaryContact"
              placeholder="Enter Secondary Contact"
            />
            {touched.secondaryContact && errors.secondaryContact && (
              <small className="errorMessage">{errors.secondaryContact}</small>
            )}
            <FaPhone className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="city" style={{marginBottom:"5px"}}>city</label>
            <input
              type="text"
              id="city"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.city}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="city"
              placeholder="Enter city"
            />
            {touched.city && errors.city && (
              <small className="errorMessage">{errors.city}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="district" style={{marginBottom:"5px"}}>District</label>
            <input
              type="text"
              id="district"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.district}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="district"
              placeholder="Enter District"
            />
            {touched.district && errors.district && (
              <small className="errorMessage">{errors.district}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="state" style={{marginBottom:"5px"}}>State</label>
            <input
              type="text"
              id="state"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.state}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="state"
              placeholder="Enter state"
            />
            {touched.state && errors.state && (
              <small className="errorMessage">{errors.state}</small>
            )}
            <FaTreeCity className="create_lead_input_icon" />
          </div>
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="country" style={{marginBottom:"5px"}}>Country</label>
            <input
              type="text"
              id="country"
              maxLength={30}
              className="form-control create_lead_form_input"
              value={values.country}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="country"
              placeholder="Enter country name"
            />
            {touched.country && errors.country && (
              <small className="errorMessage">{errors.country}</small>
            )}
            <FaLandmarkFlag className="create_lead_input_icon" />
          </div>
         
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="pinCode" style={{marginBottom:"5px"}}>PinCode</label>
            <input
              type="text"
              id="pinCode"
              maxLength={6}
              className="form-control create_lead_form_input"
              value={values.pinCode}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="pinCode"
              placeholder="Enter pinCode"
            />
            {touched.pinCode && errors.pinCode && (
              <small className="errorMessage">{errors.pinCode}</small>
            )}
            <FaLandmarkFlag className="create_lead_input_icon" />
          </div>
{/*   
          <div className="form-group createLeadInput col-xl-4">
            <label htmlFor="address" style={{marginBottom:"5px"}}>Address</label>
            <input
              type="text"
              id="address"
              maxLength={100}
              className="form-control create_lead_form_input"
              value={values.address}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="address"
              placeholder="Enter address"
            />
            {touched.address && errors.address && (
              <small className="errorMessage">{errors.address}</small>
            )}
            <FaLandmarkFlag className="create_lead_input_icon" />
          </div> */}
        </div>
        {/* Description */}
        <div className="row">
          <p className="create_lead_section2_description">Description</p>
          <div className="form-group col-xl-8" style={{resize:"none"}}>
            {/* <label
              htmlFor="description"
              className="create_lead_section2_description_label"
              style={{marginBottom:"5px"}}
            >
              Description
            </label> */}
            <textarea
              id="description"
              maxLength={150}
              className="form-control create_lead_form_input"
              value={values.description}
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name="description"
              rows="3"
              placeholder="Enter description"
            ></textarea>
          </div>
          {touched.description && errors.description && (
            <small className="errorMessage">{errors.description}</small>
          )}
      
        </div>
        {/* Submit Button */}
        <div className="text-center">
          <button className="create_lead_form_submitBtn" type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </div>
      </form>
      <Toast showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
};

export default CreateLeadForm;
